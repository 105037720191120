import { BrowserRouter as Router } from 'react-router-dom'
import { HelveticaForTarget } from '@enterprise-ui/component-font'
import {
  AuthProvider,
  ShouldSendHeaders,
  isTargetDomain,
} from '@praxis/component-auth'
import { EnvProvider } from '@praxis/component-runtime-env'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Main } from './globalComponents/Main'
import commonConfig from './configs/commonconfig'
import { UserProfileProvider } from './context/UserProfile/UserProfileProvider'
import { AttachmentProvider } from './context/Attachments/AttachmentProvider'
import './globalStyles/customStyles.scss'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-autocomplete'
import '@enterprise-ui/canvas-ui-css-datepicker'
import '@enterprise-ui/canvas-ui-css-gallery'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 0 } },
})

const App = () => {
  const isPartnersOnlineDomain = (url: string): boolean =>
    /^([^/]+:)?\/{2,3}[^/]+?(\.partnersonline\.com|\.tgt)(:|\/|$)/i.test(url)

  const shouldSendHeaders: ShouldSendHeaders = (url) => {
    const result = isTargetDomain(url) || isPartnersOnlineDomain(url)
    return result
  }

  return (
    <>
      <EnvProvider
        configPath={
          process.env.NODE_ENV === 'development'
            ? '/config.json'
            : '/app-environment'
        }
        commonConfig={commonConfig}
      >
        {(env: any) => {
          const auth: any = env.env.auth
          return (
            <AuthProvider
              hideLoadingIndicator
              shouldSendHeaders={shouldSendHeaders}
              {...auth}
            >
              <QueryClientProvider client={queryClient}>
                <HelveticaForTarget variants={['n4', 'n5', 'n7']} />
                <UserProfileProvider>
                  <ToastProvider location="top">
                    <AttachmentProvider>
                      <Router>
                        <Main />
                      </Router>
                    </AttachmentProvider>
                  </ToastProvider>
                </UserProfileProvider>
              </QueryClientProvider>
            </AuthProvider>
          )
        }}
      </EnvProvider>
    </>
  )
}

export default App
