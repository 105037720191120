export function getCurrency(val: any, currencyType: string) {
  if (val === undefined) {
    return 'None'
  }
  return new Intl.NumberFormat(currencyType === 'INR' ? 'en-IN' : 'en-US', {
    style: 'currency',
    currency: currencyType,
  }).format(Number(val.toString()?.replace(/[^0-9.-]+/g, '')))
}

export function getCompactNumber(number: number | undefined, digits = 1) {
  if (number == null) {
    return ''
  }
  if (number < 1000) {
    return number
  } else if (number >= 1000 && number < 1_000_000) {
    return (number / 1000).toFixed(digits) + 'K'
  } else if (number >= 1_000_000 && number < 1_000_000_000) {
    return (number / 1_000_000).toFixed(digits) + 'M'
  } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
    return (number / 1_000_000_000).toFixed(digits) + 'B'
  } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
    return (number / 1_000_000_000_000).toFixed(digits) + 'T'
  }
}
