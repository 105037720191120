import { Button, Spinner } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { DownloadIcon } from '@enterprise-ui/icons'
import React from 'react'
import { CSVLink } from 'react-csv'
import { getDownloadResults } from '../../utils/ExcelFormatter'
import { useUserProfile } from '../../context/UserProfile/UserProfileProvider'

interface ExcelProps {
  onClick: any
  columns: any
  data: any
  loading?: any
  csvLink: any
}

export const ExportToExcel: React.FC<ExcelProps> = ({
  onClick,
  columns,
  data,
  loading,
  csvLink,
}) => {
  const [userProfile] = useUserProfile()
  return (
    <>
      {loading && <Spinner size="dense" />}
      <Button
        type="secondary"
        onClick={() => {
          onClick()
        }}
        className="hc-mh-sm"
      >
        <EnterpriseIcon icon={DownloadIcon} />
        &nbsp;Export To Excel
      </Button>
      {data ? (
        <CSVLink
          data={getDownloadResults(data, userProfile.isVendor)}
          headers={columns}
          filename="nonedi_invoices.csv"
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
      ) : undefined}
    </>
  )
}
