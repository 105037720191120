import React from 'react'
import { Heading, Grid, Card, Divider } from '@enterprise-ui/canvas-ui-react'
import { useNavigate } from 'react-router-dom'

export interface ReportSectionProps {
  title: string
  cards: ReportCardProps[]
}

export interface ReportCardProps {
  cardTitle: string
  url: string
  size?: number
}

export const ReportSection: React.FC<ReportSectionProps> = ({
  title,
  cards,
}) => {
  const navigate = useNavigate()
  return (
    <>
      <Grid.Container>
        <Heading className="hc-mb-dense" size={4} style={{ padding: '20px' }}>
          {title}
        </Heading>
        <Divider />
      </Grid.Container>
      <Grid.Container style={{ padding: '10px' }}>
        {cards.map((card) => (
          <Grid.Item xs={card.size ?? 4} key={card.cardTitle}>
            <Card style={{ height: '50px', position: 'relative' }}>
              <Grid.Item
                className="hc-ta-center"
                style={{ padding: '2px' }}
                onClick={() => {
                  navigate(card.url)
                }}
              >
                <div style={{ padding: '10px' }}>{card.cardTitle}</div>
              </Grid.Item>
            </Card>
          </Grid.Item>
        ))}
      </Grid.Container>
    </>
  )
}
