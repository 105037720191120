import { useState, useEffect } from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import { LogIn } from './LogIn'
import { MainRouter } from './MainRouter'
import { Header } from './Header'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { isSSOSessionActive } from '../services/auth.service'

export const Main = () => {
  const auth = useAuth()
  const { isAuthenticated } = auth

  const [isSideNavOpen, setIsSideNavOpen] = useState(true)
  const handleSideNavToggle = () => {
    setIsSideNavOpen(!isSideNavOpen)
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const handleVisibilityChange = () => {
    isSSOSessionActive()
  }

  if (isAuthenticated()) {
    return (
      <Layout
        theme="target"
        darkMode="false"
        data-testid="mainAuthenticatedLayout"
        fullWidth
      >
        <ToastProvider location="top">
          <Header onSideNavToggle={handleSideNavToggle} />
          <MainRouter />
        </ToastProvider>
      </Layout>
    )
  } else {
    return <LogIn />
  }
}
