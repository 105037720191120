import { Layout, Grid, Heading, Button } from '@enterprise-ui/canvas-ui-react'
import {
  CancelIcon,
  CheckIcon,
  EnterpriseIcon,
  PencilIcon,
} from '@enterprise-ui/icons'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ExemptionVendorResponse } from '../../api/models/ExemptionVendor'
import { useState, useEffect } from 'react'
import { useExemptionVendor } from '../../api/hooks/useExemptionVendor'
import { EditVendor } from './EditVendor'
import { AddVendorWrapper } from './AddVendorWrapper'

export const ExemptionVendorList = () => {
  const [vendorList, setVendorList] = useState<ExemptionVendorResponse[]>([])
  const { getAllVendors } = useExemptionVendor()
  const [showCreateScreen, setShowCreateScreen] = useState<Boolean>(false)
  const [showUpdateScreen, setShowUpdateScreen] = useState<Boolean>(false)
  const [vendorId, setVendorId] = useState(0)

  useEffect(() => {
    getAllVendors()
      .then((response: any) => {
        setVendorList(response.exemptionVendorResponseList)
        console.log('data', response)
      })
      .catch((error: any) => console.log(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const columns: ColDef[] = [
    {
      field: 'exemptionVendorId',
      headerName: 'Action',
      pinned: 'left',

      maxWidth: 100,
      cellRenderer: (props: any) => {
        return (
          <Button
            iconOnly
            aria-label="Edit"
            onClick={() => {
              setShowUpdateScreen(true)
              setVendorId(props.value)
            }}
          >
            <EnterpriseIcon icon={PencilIcon}></EnterpriseIcon>
          </Button>
        )
      },
    },
    {
      field: 'vendorNumber',
      headerName: 'Vendor Number',
      sortable: true,
    },
    {
      field: 'vendorName',
      headerName: 'Vendor Name',
      sortable: true,
      minWidth: 300,
    },
    {
      field: 'active',
      headerName: 'Active',
      maxWidth: 100,
      cellRenderer: (props: any) => {
        if (props.value === true) {
          return <EnterpriseIcon icon={CheckIcon} color="#32864e" size="lg" />
        }
        return <EnterpriseIcon icon={CancelIcon} color="#ae570f" size="lg" />
      },
    },
    {
      field: 'exemptStartDate',
      headerName: 'Exemption Start Date',
    },
    {
      field: 'exemptEndDate',
      headerName: 'Exemption End Date',
    },
    {
      field: 'permanentExemptFlag',
      headerName: 'Permanent Exemption',
      cellRenderer: (props: any) => {
        if (props.value === true) {
          return <EnterpriseIcon icon={CheckIcon} color="#32864e" size="lg" />
        }
        return <EnterpriseIcon icon={CancelIcon} color="#ae570f" size="lg" />
      },
    },
    // {
    //     field: 'ediStatus',
    //     headerName: 'Edi Status',
    //     cellRenderer: (props: any) => {
    //         if (props.value === true) {
    //           return <EnterpriseIcon icon={CheckIcon} color="#32864e" size="lg" />
    //         }
    //         return <EnterpriseIcon icon={CancelIcon} color="#ae570f" size="lg" />
    //       },
    // }
  ]
  return (
    <>
      <Layout.SectionHeader>
        <Grid.Container justify="space-between">
          <Grid.Item xs={7}>
            <Heading size={2}>Exemption Vendors</Heading>
          </Grid.Item>
          <Grid.Item xs={2}>
            <Button
              type="primary"
              onClick={() => {
                setShowCreateScreen(true)
                console.log('createScreen:', showCreateScreen)
              }}
            >
              Add Vendors
            </Button>
          </Grid.Item>
        </Grid.Container>
        <Grid.Container style={{ padding: '20px' }}>
          <Grid.Item xs={9}>
            <div style={{ flex: '1 1 auto', height: '80vh', width: '123%' }}>
              <AutoSizer>
                {({ height, width }: { height: number; width: number }) => (
                  <div
                    className="ag-theme-alpine"
                    style={{ height: height, width: width }}
                  >
                    <AgGridReact
                      rowData={vendorList}
                      pagination={true}
                      columnDefs={columns}
                    />
                  </div>
                )}
              </AutoSizer>
            </div>
          </Grid.Item>
        </Grid.Container>
        <AddVendorWrapper
          isVisible={showCreateScreen}
          setShowCreateScreen={setShowCreateScreen}
        />
        <EditVendor
          isVisible={showUpdateScreen}
          setShowUpdateScreen={setShowUpdateScreen}
          id={vendorId}
        />
      </Layout.SectionHeader>
    </>
  )
}
