import { Button, Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import { useState } from 'react'
import { InvoiceResponse } from '../../api/models/InvoiceResponse'
import { useInvoice } from '../../api/hooks/useInvoice'

interface StatusProps {
  status: any
  invcData: InvoiceResponse
  setInvcData: any
  setIsIndexedOrRejected: any
}

export const StatusUpdate: React.FC<StatusProps> = ({
  status,
  invcData,
  setInvcData,
  setIsIndexedOrRejected,
}) => {
  const [isModalVisible, setModalVisible] = useState(false)
  const [comment, setComment] = useState('')
  const { session } = useAuth()
  const { updateInvoice } = useInvoice()

  const handleSave = (updateInv: any) => {
    updateInvoice(invcData?.invoiceId, updateInv)
      .then((data) => {
        setInvcData(data)
        setComment('')
        setModalVisible(false)
      })
      .catch(() => {})
  }

  return (
    <Grid.Item>
      <Modal
        isVisible={isModalVisible}
        size="dense"
        headingText={`Change Status to ${status}?`}
        onRefuse={() => {
          setModalVisible(false)
        }}
        style={{ overflowY: 'visible' }}
      >
        <Grid.Container className="hc-pa-md">
          <Grid.Item xs={12}>
            <Form.Field
              id={status}
              label="Add your comments (optional)"
              type="textarea"
              onChange={(e: any) => {
                setComment(e.target.value)
              }}
              value={comment}
            />
          </Grid.Item>
          <Grid.Container direction="row-reverse" className="hc-pr-md hc-pb-md">
            <Grid.Item>
              <Button
                type="primary"
                onClick={() => {
                  let userName = session?.userInfo?.fullName
                  let auditEntry = {
                    userId: userName,
                    activityDescription: `Invoice was ${status}`,
                    comment: comment ?? '',
                    event: status,
                    status: status,
                    activityTimestamp: new Date(),
                  }
                  let updateInvc = Object.assign({}, invcData, {
                    status: status,
                    auditEntry: auditEntry,
                  })
                  handleSave(updateInvc)
                  if (status === 'Indexed' || status === 'Rejected') {
                    setIsIndexedOrRejected(true)
                  }
                }}
              >
                Save
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                type="secondary"
                onClick={() => {
                  // TODO: handleModalCancel()
                }}
              >
                Cancel
              </Button>
            </Grid.Item>
          </Grid.Container>
        </Grid.Container>
      </Modal>
      <Button
        type={status === 'Rejected' ? 'destructive' : 'primary'}
        onClick={() => {
          setModalVisible(true)
        }}
      >
        Set Status to {status}
      </Button>
    </Grid.Item>
  )
}
