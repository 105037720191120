export const excelDownloadHeaders = [
  { label: 'Invoice ID', key: 'invoiceId' },
  { label: 'Vendor ID', key: 'vendorNumber' },
  { label: 'Vendor Name', key: 'vendorName' },
  { label: 'Vendor Contact Name', key: 'vendorContactName' },
  { label: 'Vendor Email Address', key: 'vendorEmailAddress' },
  { label: 'Status', key: 'status' },
  { label: 'Edi Capable', key: 'isEdiCapable' },
  { label: 'Charge Fee Exemted', key: 'isChargeFeeExempted' },
  { label: 'Invoice Number', key: 'invoiceNumber' },
  { label: 'Invoice Amount', key: 'invoiceAmount' },
  { label: 'Allowance Amount', key: 'allowanceAmount' },
  { label: 'Invoice Date', key: 'invoiceDate' },
  { label: 'Purchase Order Number', key: 'purchaseOrderNumber' },
  { label: 'Department ID', key: 'departmentId' },
  { label: 'Location ID', key: 'locationId' },
  { label: 'Create User', key: 'createUser' },
  { label: 'Create Timestamp', key: 'createTimestamp' },
  { label: 'Assigned Team Member', key: 'assignedUserName' },
]
//TODO: add update ts, user, lanId - assigned user and new fields
