import React from 'react'
import { Card, Grid, Layout } from '@enterprise-ui/canvas-ui-react'

import { SectionHeader } from './SectionHeader'

interface Props {
  error: any
}

export const ErrorContent: React.FC<Props> = ({ error }) => {
  return (
    <>
      <SectionHeader pageHeading="Error" />
      <Layout.Body data-testid="layoutWithErrorContent">
        <Card>
          <Grid.Container>
            <Grid.Item>
              <p id={'errorContent'}>Your error content: {error?.message}</p>
            </Grid.Item>
          </Grid.Container>
        </Card>
      </Layout.Body>
      <Layout.Rail data-testid="layoutWithErrorRailContent">
        <Card>
          <Grid.Container>
            <Grid.Item>
              <p>Your help content</p>
            </Grid.Item>
          </Grid.Container>
        </Card>
      </Layout.Rail>
    </>
  )
}
