/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState, useEffect } from 'react'
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table'
import {
  Grid,
  Pagination,
  Table,
  Form,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { useNavigate } from 'react-router'
import { InvoiceResponse } from '../../api/models/InvoiceResponse'
import { useInvoice } from '../../api/hooks/useInvoice'
import { useDashboard } from '../../context/Invoices/DashboardProvider'
import { useUserProfile } from '../../context/UserProfile/UserProfileProvider'
import { buildQuery } from '../../utils/SearchUtils'
import { useLocation } from 'react-router-dom'
import { Role } from '../../context/UserProfile/UserProfileContext'
import { useAuth } from '@praxis/component-auth'
import LoadingSpinner from '../../globalComponents/LoadingSpinner'

interface InvoicesTableProp {
  setSearchQuery: any
  sortColumn: any
  sortAscending: boolean
}
export const InvoicesTable: React.FC<InvoicesTableProp> = ({
  setSearchQuery,
  sortColumn,
  sortAscending,
}) => {
  const fetchIdRef = React.useRef(0)
  const [invoices, setInvoices] = useState<InvoiceResponse[]>([])
  const [data, setData] = useState(invoices)

  const [pageIndex, setPageIndex] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(50)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [totalInvoices, setTotalInvoices] = useState<number>(0)

  const [loading, setLoading] = useState<boolean>(false)

  const auth = useAuth()
  const [dashboard] = useDashboard()
  const [userProfile] = useUserProfile()
  const navigate = useNavigate()
  const location = useLocation()
  const makeToast = ToastProvider.useToaster()

  const { getInvoicesForAppliedFilters } = useInvoice()

  const columns = useMemo(
    () => [
      {
        header: 'Request ID',
        accessorKey: 'invoiceId',
        size: 1,
      },
      {
        header: 'Invoice #',
        accessorKey: 'invoiceNumber',
        size: 1,
      },
      {
        header: 'Vendor #',
        accessorKey: 'vendorNumber',
        size: 1,
      },
      {
        header: 'Vendor Name',
        accessorKey: 'vendorName',
        size: 2,
      },
      {
        header: 'PO Number',
        accessorKey: 'purchaseOrderNumber',
        size: 1,
      },
      {
        header: 'Department',
        accessorKey: 'departmentId',
        size: 1,
      },
      {
        header: 'Location',
        accessorKey: 'locationId',
        size: 1,
      },
      {
        header: 'Invoice Amount',
        accessorKey: 'invoiceAmount',
        size: 1,
      },
      {
        header: 'Invoice Date',
        accessorKey: 'invoiceDate',
        size: 1,
      },
      {
        header: 'Status',
        accessorKey: 'status',
        size: 1,
      },
    ],
    []
  )

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    },
  })

  const fetchInvoices = React.useCallback(
    async ({ pageSize, pageIndex }: any) => {
      const fetchId = ++fetchIdRef.current
      if (fetchId === fetchIdRef.current) {
        setLoading(true)
        try {
          let query: any =
            buildQuery(location, dashboard, auth.session?.userInfo?.lanId) ?? {}
          if (
            userProfile?.userRole === Role.VENDOR &&
            query['vendorNumber'] == null
          ) {
            query['vendorNumber'] = userProfile?.vendorList?.map(
              (vendor: any) => vendor?.vendorId
            )
          }

          const response = await getInvoicesForAppliedFilters({
            ...query,
            page: pageIndex + 1,
            perPage: pageSize,
            sortBy: sortColumn?.id,
            sortOrder: sortAscending ? 'ASC' : 'DESC',
          })

          setInvoices(response?.invoiceResponseList ?? [])
          setData(response?.invoiceResponseList ?? [])
          setTotalPages(Math.ceil(response?.totalCount / pageSize))
          setTotalInvoices(response?.totalCount)
          setLoading(false)
          setSearchQuery({
            ...query,
            page: 1,
            perPage: response?.totalCount,
            sortBy: sortColumn?.id,
            sortOrder: sortAscending ? 'ASC' : 'DESC',
          })
        } catch (error) {
          console.log('Error in Fetch Invoices API', error)
          setLoading(false)
          makeToast({
            autoHideDuration: 4000,
            type: 'error',
            heading: 'Error while fetching invoices',
            message:
              'Make sure you are connected to network or try again later',
          })
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dashboard, sortColumn, sortAscending, userProfile]
  )

  useEffect(() => {
    if (userProfile !== undefined) {
      if (userProfile.isVendor && userProfile.vendorList) {
        fetchInvoices({ pageSize: pageSize, pageIndex: pageIndex })
      }

      if (!userProfile.isVendor) {
        fetchInvoices({ pageSize: pageSize, pageIndex: pageIndex })
      }
    }
  }, [userProfile, fetchInvoices, pageIndex, pageSize])

  if (loading) {
    return <LoadingSpinner />
  }
  return (
    <React.Fragment>
      <Grid.Container>
        <Grid.Item xs={12}>
          <Table name="Invoice List">
            <Table.Head>
              <Table.Row>
                {table.getAllColumns().map((column) => (
                  <Table.Header key={column.id} xs={column.columnDef.size}>
                    {column.columnDef.header}
                  </Table.Header>
                ))}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {table?.getRowModel()?.rows?.map((row) => (
                <Table.Row
                  key={row.id}
                  onClick={() => {
                    navigate(`/view/${row?.original?.invoiceId}`)
                  }}
                >
                  {row?.getVisibleCells()?.map((cell) => {
                    return (
                      <Table.Data key={cell.id} xs={cell.column.columnDef.size}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.Data>
                    )
                  })}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Item>
      </Grid.Container>
      <Grid.Container direction="row-reverse" className="hc-pv-none">
        <Grid.Item className="hc-pb-none">
          <Form.Field
            data-testid="pageSize_select"
            id="pageSize_select"
            value={pageSize}
            onUpdate={(e: any, a: any) => {
              setPageSize(Number(a))
            }}
            type="select"
            options={[
              {
                value: 10,
                label: 'Show 10 Invoices',
              },
              {
                value: 25,
                label: 'Show 25 Invoices',
              },
              {
                value: 50,
                label: 'Show 50 Invoices',
              },
            ]}
          />
        </Grid.Item>
        <Grid.Item>
          <Pagination
            currentPage={pageIndex + 1}
            onRequestFirst={() => {
              setPageIndex(0)
            }}
            onRequestPrev={() => {
              setPageIndex(pageIndex - 1)
            }}
            onRequestNext={() => {
              setPageIndex(pageIndex + 1)
            }}
            onRequestLast={() => {
              setPageIndex(totalPages - 1)
            }}
            totalPages={totalPages}
          />
        </Grid.Item>
      </Grid.Container>
    </React.Fragment>
  )
}
//if export toe xcel works with invoices/data remove setSearchQuery
