import { InferType, boolean, number, object, string } from 'yup'
import { AddVendor } from './AddVendor'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import { useExemptionVendor } from '../../api/hooks/useExemptionVendor'
import { Formik, FormikProps } from 'formik'
import { formatVendor } from '../submitInvoice/utils'

export interface ExemptionVendorForm extends InferType<typeof schema> {}

const initalFormValues: ExemptionVendorForm = {
  vendorNumber: 0,
  vendorName: '',
  active: true,
  permanentExemptFlag: true,
  exemptStartDate: '',
  exemptEndDate: '',
}

const schema = object({
  vendorName: string()
    .required('Invalid Vendor Number')
    .test('valid-vendorName', 'Vendor number is not valid', function (value) {
      if (!value) {
        return false
      } else return true
    }),
  vendorNumber: number()
    .required('Please Select a Valid Vendor number')
    .positive('Please Select a Valid Vendor number'),
  exemptStartDate: string(),
  exemptEndDate: string().test(
    'valid-exemptEnd-date',
    'Exempt End Date should be more than Start Date',
    function (value) {
      const { exemptStartDate } = this.parent
      if (!value && !exemptStartDate) {
        return true
      } else if (!value || !exemptStartDate) {
        return false
      }
      const startDate = new Date(exemptStartDate)
      const exempEndDate = new Date(value)
      if (exempEndDate > startDate) {
        return true
      }
      return false
    }
  ),
  active: boolean().required(),
  permanentExemptFlag: boolean().required(),
})

export const AddVendorWrapper = (props: any) => {
  const makeToast = useToaster()
  const { postVendor } = useExemptionVendor()
  const handleSubmitVendor = async (
    formikProps: FormikProps<ExemptionVendorForm>
  ) => {
    const {
      values,
      submitForm,
      setSubmitting,
      setFieldValue,
      isValid,
      errors,
    } = formikProps
    if (values.permanentExemptFlag) {
      setFieldValue('exemptStartDate', '')
      setFieldValue('exemptEndDate', '')
    }
    console.log('formik values', values)
    await submitForm()

    if (!isValid) {
      console.log('errors', errors)
      return
    }
    try {
      postVendor(formatVendor(values))
        .then((response: any) => {
          props.setShowCreateScreen(false)
          window.location.reload()
          makeToast({
            autoHideDuration: 5000,
            type: 'success',
            heading: 'Successfully submitted!',
            message: 'Your request has been submitted.',
          })
          console.log('data', response)
        })
        .catch((error: any) => console.log(error))
    } catch (error: any) {
      let message = error?.response?.data?.message
      makeToast({
        type: 'error',
        message,
        heading: 'Vendor Submisson Failed',
      })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initalFormValues}
      validationSchema={schema}
      onSubmit={() => {}}
      initialTouched={true}
    >
      <AddVendor
        handleSubmitVendor={handleSubmitVendor}
        isVisible={props.isVisible}
        setShowCreateScreen={props.setShowCreateScreen}
      />
    </Formik>
  )
}
