import { useState, useEffect } from 'react'
// import { useInvoice } from '../../hooks/useInvoice'
// import { InvoiceReport } from '../../models/InvoiceReportResponse'
import {
  Card,
  Grid,
  Heading,
  Layout,
  Input,
  Form,
} from '@enterprise-ui/canvas-ui-react'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-datepicker'

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css' // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css' // Optional theme CSS
import AutoSizer from 'react-virtualized-auto-sizer'
import { RangeFilter } from './RangeFilter'
import { useLocation, useNavigate } from 'react-router-dom'
import { useInvoice } from '../../api/hooks/useInvoice'
import { InvoiceReport } from '../../api/models/InvoiceReportResponse'

export const ReportsTable = () => {
  const [invoiceReports, setInvoiceReports] = useState<InvoiceReport[]>([])
  const { getReport } = useInvoice()
  const [displayField, setDisplayField] = useState('count')
  const [filterType, setFilterType] = useState<string>()
  const location = useLocation()
  const navigate = useNavigate()
  let aggParams = ''

  let params = '' // to get the part after aggregated reports in url
  if (!location.pathname.includes('aggregateBy')) {
    params = location.search?.split('?')[1] ?? ''
  } else {
    params = location.pathname?.split('aggregated_reports/')[1] ?? ''
    aggParams = params?.split('aggregateBy=')[1] ?? ''
  }

  useEffect(() => {
    console.log('params', params)
    getReport(params)
      .then((response: any) => {
        setInvoiceReports(response)
      })
      .catch((error: any) => console.log(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (!location.pathname.includes('aggregateBy')) {
      let type = location.search?.split('aggregateBy=')[1] ?? ''
      if (type.includes('invoiceDateFrom')) {
        let temp = type.split('&invoiceDateFrom')[0] ?? ''
        console.log('temp', temp)
        setFilterType(temp)
      } else {
        console.log('type', type)
        setFilterType(type)
      }
    } else {
      let type = location.pathname?.split('aggregateBy=')[1] ?? ''
      console.log('temp1', type)
      setFilterType(type)
    }
  }, [location])

  const handleDisplayFieldChange = (event: any) => {
    setDisplayField(event.target.value)
  }

  const subGroupFields = Array.from(
    new Set(
      invoiceReports.flatMap((report) =>
        report.subGroupFieldCounts.map(
          (subGroup: any) => subGroup.subGroupField
        )
      )
    )
  )

  const columnDefs = [
    { field: 'groupField', headerName: 'Vendor' },
    ...subGroupFields.map((field) => ({ field })),
  ]

  const firstColumnDef = columnDefs[0]
  const sortedColumnDefs = columnDefs.slice(1).sort((a, b) => {
    const [monthA, yearA]: string[] = a.field.split(' ')
    const [monthB, yearB]: string[] = b.field.split(' ')

    // Parsing month and year values to compare
    const dateA: Date = new Date(`${yearA}-${monthA}-01`)
    const dateB: Date = new Date(`${yearB}-${monthB}-01`)

    // Comparing the dates in descending order
    return dateB.getTime() - dateA.getTime()
  })

  const finalColumnDefs = [firstColumnDef, ...sortedColumnDefs]

  type RowDataItem = {
    groupField: string
    [key: string]: number | string
  }

  // Create rowData array
  const rowData: RowDataItem[] = invoiceReports.map((report) => {
    const row: RowDataItem = { groupField: report.groupField.toString() } // Initialize row object with groupField

    // Loop through subGroupFieldCounts and set count values for each subGroupField
    report.subGroupFieldCounts.forEach((subGroup: any) => {
      if (displayField === 'count') {
        row[subGroup.subGroupField] = subGroup.count
      } else {
        row[subGroup.subGroupField] = subGroup.totalAmount + '$'
      }
    })
    return row
  })

  function handleInputSelectChange(event: any) {
    const value = event
    if (value) {
      navigate(`/reports/aggregated_reports/aggregateBy=${value}`)
    }
  }

  return (
    <>
      <Layout.Body data-testid="homePage" includeRail>
        <Grid.Container>
          <Grid.Item>
            <Heading style={{ padding: '30px' }}>Aggregation by Vendor</Heading>
          </Grid.Item>
          <Grid.Item style={{ alignSelf: 'center' }} xs={20}>
            {console.log('aggParams', aggParams)}
            <RangeFilter aggParams={aggParams} />
            {/*// TODO: aggParams becomes null*/}
          </Grid.Item>
        </Grid.Container>

        <Card className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs={9}>
              <div style={{ flex: '1 1 auto', height: '80vh', width: '100%' }}>
                <AutoSizer>
                  {({ height, width }: { height: number; width: number }) => (
                    <div
                      className="ag-theme-alpine"
                      style={{ height: height, width: width }}
                    >
                      <AgGridReact
                        rowData={rowData}
                        pagination={true}
                        columnDefs={finalColumnDefs}
                      />
                    </div>
                  )}
                </AutoSizer>
              </div>
            </Grid.Item>
            <Grid.Item xs={3}>
              <Grid.Item style={{ padding: '10px' }}>
                <h4>Select Report by:</h4>
                <Input.Radio
                  id="demo_00"
                  options={[
                    { value: 'count', label: 'Count' },
                    { value: 'amount', label: 'Amount' },
                  ]}
                  onChange={handleDisplayFieldChange}
                  value={displayField}
                />
              </Grid.Item>
              <Grid.Item style={{ padding: '10px' }}>
                <h4>Select Another Aggregation Report</h4>
                <Form.Field
                  type="select"
                  id="report_type"
                  value={filterType}
                  options={[
                    {
                      value: 'vendorName,status',
                      label: 'By Vendor and Status',
                      disabled: false,
                    },
                    {
                      value: 'vendorName,purchaseOrderNumber',
                      label: 'By Vendor and Purchase Order Number',
                      disabled: false,
                    },
                    {
                      value: 'vendorName,invoiceDate',
                      label: 'By Vendor and Invoice Date',
                      disabled: false,
                    },
                  ]}
                  onUpdate={(id: any, event: any) => {
                    handleInputSelectChange(event)
                    setFilterType(event)
                  }}
                />
              </Grid.Item>
            </Grid.Item>
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  )
}
