import { createContext } from 'react'

export type UserProfileAction =
  | {
      type: 'SET_USER_ROLE'
      userId?: string
      userRole?: Role
      isVendor: boolean
      isAdmin: boolean
    }
  | { type: 'SET_VENDOR_LIST'; vendorList: Vendor[] }

export interface UserProfileState {
  userId?: string
  userRole?: Role
  isVendor?: boolean
  isAdmin?: boolean
  vendorList?: Vendor[]
}

export interface Vendor {
  vendorId: string
  vendorName: string
}

export interface VendorResponse {
  vendorId: number
  vendorName: string
  importVendorFlag: string
  vendorTypeCode: string
  vendorTypeName: string
  dvsFlag: string
  bankName?: string
}

export enum Role {
  USER = 'User',
  VENDOR = 'Vendor',
  ADMIN = 'Admin',
}

const UserProfileStateContext = createContext<UserProfileState>({})
UserProfileStateContext.displayName = 'UserProfileStateContext'

const UserProfileDispatchContext = createContext<
  React.Dispatch<UserProfileAction>
>(() => null)
UserProfileStateContext.displayName = 'UserProfileDispatchContext'

export { UserProfileStateContext, UserProfileDispatchContext }
