import React from 'react'
import { Divider } from '@enterprise-ui/canvas-ui-react'
import { useUserProfile } from '../../context/UserProfile/UserProfileProvider'
import { useDashboard } from '../../context/Invoices/DashboardProvider'
import { buildUrl, filterUser, removeFilter } from '../../utils/SearchUtils'
import { Filter } from './Filter'
import { useLocation, useNavigate } from 'react-router-dom'
import { ActiveSearchFilters } from './Filter/ActiveSearchFilters'
import { Role } from '../../context/UserProfile/UserProfileContext'

export default function SideFilters() {
  const [userProfile] = useUserProfile()
  const [dashboard] = useDashboard()
  const navigate = useNavigate()
  const location = useLocation()

  const handleChange = (
    id: string,
    type: string,
    event: any,
    searchField?: string
  ) => {
    navigate(
      '/invoices?' +
        buildUrl(location, {
          filter: id,
          event: event,
          type: type,
          searchField: searchField,
        })
    )
  }

  const handleRemoveFilter = (
    filter: string,
    id: string | number,
    type: string,
    searchFilter?: string,
    value?: string
  ) => {
    navigate(
      '/invoices?' +
        removeFilter(location, {
          filter: filter,
          id: id,
          type: type,
          value: value,
        })
    )
  }

  const handleClearFilters = () => {
    navigate('/invoices')
  }

  return (
    <>
      <ActiveSearchFilters
        searchFilters={dashboard.filters?.searchFilters}
        sideFilters={dashboard?.filters?.sideFilters}
        handleClearSingleFilter={handleRemoveFilter}
        handleClearFilters={handleClearFilters}
        vendor={userProfile?.userRole === Role.VENDOR}
      />
      {dashboard?.filters?.sideFilters
        ?.filter((key: any) =>
          filterUser(key, userProfile?.userRole === Role.VENDOR)
        )
        .map((key: any) => (
          <React.Fragment key={key.id}>
            <Filter
              id={key.id}
              label={key.displayName}
              filters={key.values}
              onUpdate={handleChange}
              type={key.type}
              active={key.active}
              searchFields={key.searchFields}
              startExpanded={key.startExpanded}
            />

            <Divider />
          </React.Fragment>
        ))}
    </>
  )
}
