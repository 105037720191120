import { createContext } from 'react'
import { CreateDraftInvoice, Draft } from '../../api/models/Draft'

export type DraftAction =
  | {
      type: 'SET_INITIAL_DRAFT'
      payload: Draft
    }
  | { type: 'LOADING'; payload: boolean }
  | {
      type: 'UPDATE_DRAFT'
      payload: Draft
    }
  | {
      type: 'SET_DRAFT_INVOICE'
      payload: { index: number; draftInvoice: CreateDraftInvoice }
    }
  | {
      type: 'SET_DRAFT_INVOICES'
      payload: { draftInvoices: CreateDraftInvoice[] }
    }
  | {
      type: 'ADD_ATTACHMENT'
      payload: { index: number; file: any }
    }
  | {
      type: 'REMOVE_ATTACHMENT'
      payload: { index: number }
    }
  | {
      type: 'SET_ERRORS'
      payload: any[]
    }
  | {
      type: 'CLEAR'
    }

export interface DraftState {
  draft?: Draft
  draftId?: number
  loading: boolean
  draftInvoices: CreateDraftInvoice[]
  attachments: any[]
  errors: any[]
}

const DraftStateContext = createContext<DraftState>({
  loading: false,
  draftInvoices: [],
  attachments: [],
  errors: [],
})
DraftStateContext.displayName = 'DraftStateContext'

const DraftDispatchContext = createContext<React.Dispatch<DraftAction>>(
  () => null
)

DraftDispatchContext.displayName = 'DraftDispatchContext'

export { DraftStateContext, DraftDispatchContext }
