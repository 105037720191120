import {
  Button,
  Card,
  Grid,
  GridItem,
  Input,
  Layout,
  Tooltip,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { useDrafts } from './BulkProvider'
import { InvoiceSection } from './InvoiceSection'
import { VendorSelectionSection } from './VendorSelectionSection'
import { SectionHeader } from '../../globalComponents/SectionHeader'
import {
  CheckCircleIcon,
  EnterpriseIcon,
  ErrorFilledIcon,
  PlusIcon,
} from '@enterprise-ui/icons'
import { useMutation } from '@tanstack/react-query'
import { useDraft } from '../../api/hooks/useDraft'
import { useNavigate } from 'react-router-dom'
import { formatNewInvoice, initialInvoiceValues } from './utils'
import { Draft } from '../../api/models/Draft'
import { useAttachment } from '../../api/hooks/useAttachments'
import { formatBulkAttachmentMetadata } from '../submitInvoice/utils'
import { get } from 'lodash'
import { useScrollToInvoice } from './useScrollToInvoice'

export const Bulk = () => {
  const navigate = useNavigate()
  const makeToast = useToaster()

  const [state, dispatch] = useDrafts()
  const { submitDraft, updateDraft } = useDraft()
  const { addAttachments } = useAttachment()
  const [setItemRef, scrollToItem] = useScrollToInvoice(state.draftInvoices)

  const { mutate: saveDraft } = useMutation({
    mutationKey: ['updateDraft'],
    mutationFn: updateDraft,
    onError: (err: any) => {
      makeToast({
        type: 'error',
        heading: 'Unable to Save Draft',
        message: err?.message,
      })
    },
    onSuccess: (res) => {
      dispatch({ type: 'UPDATE_DRAFT', payload: res })
    },
  })

  const { mutate: submitDraftInvoices } = useMutation(submitDraft, {
    onSuccess: (draft: Draft) => {
      draft.draftInvoices.forEach((invoice, i) => {
        const attchnment = state.attachments.findLast(
          (attachment: any) => attachment.index === i
        )
        if (attchnment) {
          const attachmentMeta = {
            name: attchnment.file.name,
            parentId: invoice.invoiceId!!.toString(),
            file: attchnment.file.file,
            attachmentMetaData: formatBulkAttachmentMetadata(invoice),
            asyncUpload: true,
          }
          addAttachments([{ ...attachmentMeta }])
        }
      })
      makeToast({
        type: 'success',
        heading: 'Invoices Submitted',
        message:
          'All the invoices are submitted & attachemnts will be uploaded in background you can track progress in upload mananger',
      })
      navigate('/')
    },
    onError: (err: any) => {
      makeToast({
        type: 'error',
        heading: 'Failed Submitting the Invoice',
        message: err?.message,
      })
    },
  })

  const isValidDraft = () => {
    if (
      state.errors.length === 0 &&
      (state.draft?.draftInvoices?.length ?? 0) > 0
    ) {
      return true
    }
    return false
  }

  const handleSave = () => {
    saveDraft({
      draftId: state.draftId!!,
      updateDraftObj: {
        draftInvoices: state.draftInvoices,
        draftStatus: 'ACTIVE',
      },
    })
  }

  const handleSubmit = () => {
    submitDraftInvoices(state.draftId!!)
  }

  const handleAddInvoice = () => {
    const updatedDraftInvoices = [...state.draftInvoices]

    dispatch({
      type: 'SET_DRAFT_INVOICE',
      payload: {
        index: updatedDraftInvoices.length,
        draftInvoice: { invoice: formatNewInvoice(initialInvoiceValues) },
      },
    })
  }

  const clearInvoice = () => {
    dispatch({
      type: 'CLEAR',
    })

    saveDraft({
      draftId: state.draftId!!,
      updateDraftObj: {
        draftInvoices: [],
        draftStatus: 'ACTIVE',
      },
    })
  }

  const handleAddAttachments = (files: any) => {
    const draftInvoices = [...state.draftInvoices]

    for (let i = 0; i < files.length; i++) {
      const fileName = files?.item(i).name

      const invoiceNumber = fileName.split('.')[0]

      const draftIndex = draftInvoices.findIndex(
        (draftInvoice) => draftInvoice.invoice.invoiceNumber === invoiceNumber
      )

      if (draftIndex !== -1) {
        dispatch({
          type: 'ADD_ATTACHMENT',
          payload: {
            index: draftIndex,
            file: { file: files?.item(i), name: files?.item(i).name },
          },
        })
      }
    }
  }

  return (
    <Layout.Body includeRail className="hc-ph-3x">
      <SectionHeader pageHeading={'Submit Multiple Invoices'} filters={false} />
      <Card className="hc-pa-none top-bar" corners="none">
        <Grid.Container spacing={'none'}>
          <Grid.Item xs={8}>
            <Grid.Container className="hc-pv-sm hc-ph-4x">
              <Grid.Item>
                <Button type="destructive" onClick={() => clearInvoice()}>
                  Clear Draft
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  type="primary"
                  onClick={() => {
                    handleSave()
                  }}
                >
                  Save Draft
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  type="primary"
                  onClick={() => {
                    handleAddInvoice()
                  }}
                >
                  <EnterpriseIcon icon={PlusIcon}></EnterpriseIcon> Add Invoice
                </Button>
              </Grid.Item>

              <Grid.Item>
                <Tooltip
                  content={'Drop your files here. With Invoice number prefix'}
                  location="top"
                >
                  <Input.DropArea
                    className="hc-pv-min"
                    id="add-attachments"
                    onUpdate={(e: FormDataEvent) => {
                      const files =
                        get(e, 'dataTransfer.files') || get(e, 'target.files')
                      handleAddAttachments(files)
                    }}
                    instructionText=""
                    dropText="Add Attchments"
                    size="dense"
                    multiple
                  />
                </Tooltip>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          <GridItem xs={4}>
            <Grid.Container
              className="hc-pv-sm hc-ph-4x"
              direction="row-reverse"
            >
              <Grid.Item>
                <Button
                  type="primary"
                  onClick={() => {
                    handleSubmit()
                  }}
                  disabled={!isValidDraft()}
                >
                  Submit Invoices
                </Button>
              </Grid.Item>
              {isValidDraft() ? (
                <Grid.Item>
                  <EnterpriseIcon
                    icon={CheckCircleIcon}
                    size="xl"
                    color="green"
                  />
                </Grid.Item>
              ) : (
                <Grid.Item>
                  <EnterpriseIcon
                    icon={ErrorFilledIcon}
                    size="xl"
                    color="red"
                    onClick={() => scrollToItem(4)}
                  />
                </Grid.Item>
              )}
            </Grid.Container>
          </GridItem>
        </Grid.Container>
      </Card>

      <Grid.Container>
        <Grid.Item xs={12}>
          <VendorSelectionSection />
        </Grid.Item>
        <Grid.Item className="hc-ph-sm" xs={12}>
          {state.draftInvoices.map((invoice, i) => (
            <div key={i} ref={(el) => setItemRef(el, i)}>
              <InvoiceSection index={i} invc={invoice} />
              <br />
            </div>
          ))}
          {!state.loading && state.draftInvoices.length === 0 && (
            <InvoiceSection index={state.draftInvoices.length} />
          )}
        </Grid.Item>
        <Grid.Item className="hc-pb-4x"></Grid.Item>
      </Grid.Container>
    </Layout.Body>
  )
}
