import {
  Form,
  Modal,
  Grid,
  Button,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import { useFormikContext } from 'formik'
import { useState } from 'react'
import { useVendorApi } from '../../api/hooks/useVendorApi'
import { ExemptionVendorForm } from './AddVendorWrapper'

export const AddVendor = (props: any) => {
  const formik = useFormikContext<ExemptionVendorForm>()
  const { getVendorDetails } = useVendorApi()

  const { values, errors, touched, setFieldValue } = formik

  const [enableDateField, setEnableDateField] = useState<Boolean>(false)

  const [loading, setLoading] = useState<Boolean>(false)

  return (
    <Modal
      isVisible={props.isVisible}
      onRefuse={() => {
        props.setShowCreateScreen(false)
      }}
      headingText="Add Vendor"
    >
      <Grid.Container justify={'center'} className="hc-pa-normal">
        <Grid.Item xs={11}>
          <Grid.Container>
            <Grid.Item xs={4}>
              <Form.Field
                id="vendorNumber"
                onChange={(event: any) => {
                  setFieldValue('vendorNumber', event.target.value)
                  setLoading(true)
                  getVendorDetails(event.target.value)
                    .then((response: any) => {
                      setLoading(false)
                      setFieldValue('vendorName', response.vendorName)
                    })
                    .catch((error: any) => {
                      setFieldValue('vendorName', '')
                      console.log(error)
                    })
                }}
                value={values.vendorNumber}
                error={
                  touched.vendorNumber && errors.vendorNumber ? true : false
                }
                type="text"
                required
                errorText={errors.vendorNumber}
                label="Enter the Vendor Number"
              />
            </Grid.Item>
            <Grid.Item xs={5}>
              <Form.Field
                id="vendorName"
                value={values.vendorName}
                type="text"
                required
                label="Vendor Name"
                disabled
              />
            </Grid.Item>
            {loading && (
              <Grid.Item xs={2}>
                <Spinner />
              </Grid.Item>
            )}
          </Grid.Container>
        </Grid.Item>
        <Grid.Item xs={11}>
          <Grid.Container align="center">
            <Grid.Item xs={5}>
              <Form.Field
                id="permanentExempt"
                label="Permanent Exemption"
                onChange={(event: any) => {
                  setEnableDateField(!event.target.checked)
                  setFieldValue('permanentExemptFlag', event.target.checked)
                  if (values.permanentExemptFlag) {
                    setFieldValue('exemptStartDate', '')
                    setFieldValue('exemptEndDate', '')
                  }
                }}
                defaultValue={true}
                type="toggle"
                required
              />
            </Grid.Item>
            {/* <Grid.Item xs = {3}>
                            <Form.Field
                            id = "ediStatus" 
                            label="EDI Status"
                            onChange = {() => {}}
                            type = "toggle"
                            required
                            />
                        </Grid.Item> */}
          </Grid.Container>
        </Grid.Item>
        {enableDateField && (
          <Grid.Item xs={11}>
            <Grid.Container align="center">
              <Grid.Item xs={5}>
                <Form.Field
                  id="exemptStartDate"
                  label="Exemption Beginning"
                  onChange={(event: any) => {
                    setFieldValue('exemptStartDate', event.target.value)
                  }}
                  value={values.exemptStartDate}
                  error={
                    touched.exemptStartDate && formik.errors.exemptStartDate
                      ? true
                      : false
                  }
                  errorText={errors.exemptStartDate}
                  type="date"
                />
              </Grid.Item>
              <Grid.Item xs={6}>
                <Form.Field
                  id="exemptEndDate"
                  label="Exemption Terminating"
                  onChange={(event: any) => {
                    setFieldValue('exemptEndDate', event.target.value)
                  }}
                  value={values.exemptEndDate}
                  error={
                    touched.exemptEndDate && formik.errors.exemptEndDate
                      ? true
                      : false
                  }
                  errorText={errors.exemptEndDate}
                  type="date"
                />
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        )}

        <Grid.Item xs={12}>
          <Grid.Container direction="row-reverse" spacing="dense">
            <Grid.Item>
              <Button
                type="submit"
                onClick={() => {
                  props.handleSubmitVendor(formik)
                }}
                // disabled={isSubmitting}
              >
                Submit
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                onClick={() => {
                  props.setShowCreateScreen(false)
                }}
              >
                Cancel
              </Button>
            </Grid.Item>
            <Grid.Item></Grid.Item>
            <Grid.Item>
              <Form.Field
                id="active"
                label="Active"
                onChange={(event: any) => {
                  setFieldValue('active', event.target.checked)
                }}
                defaultValue={true}
                type="toggle"
                required
              />
            </Grid.Item>
          </Grid.Container>
          <Grid.Container spacing="dense"></Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </Modal>
  )
}
//TODO: do check for duplicate vendor submission
