import {
  Button,
  Card,
  Grid,
  Layout,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'

export const LogIn = () => {
  const auth = useAuth()
  const { login } = auth

  if (!auth.session) {
    login({ redirect: window.location.href })
  }

  return (
    <Layout data-testid="loginLayout" fullWidth>
      <Layout.Body includeRail>
        <Grid.Container
          className="praxcss-height100vh"
          align="center"
          justify="center"
        >
          <Grid.Item xs={10} md={6} lg={4}>
            <Card className="hc-pa-normal">
              <Heading className="hc-ta-center hc-pb-normal">
                I N V O I C I O
              </Heading>
              <p>Please login to access content.</p>
              <Button
                data-testid="loginButton"
                type="primary"
                size="expanded"
                fullWidth
                onClick={() => login({ redirect: window.location.href })}
              >
                Log In
              </Button>
            </Card>
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
    </Layout>
  )
}

export default LogIn
