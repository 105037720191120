import { useState } from 'react'
import { Drawer, Layout } from '@enterprise-ui/canvas-ui-react'
import { SectionHeader } from '../../globalComponents/SectionHeader'
import { SubmitInvoiceWrapper } from '../submitInvoice/SubmitInvoiceWrapper'
import DashboardSection from './DashboardSection'

export const Dashboard = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [sideFilterVisible, setSideFilterVisible] = useState<boolean>(true)

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setIsDrawerOpen(false)
  }
  const handleShowSideFilters = () => {
    setSideFilterVisible(true)
  }
  const handleHideSideFilters = () => {
    setSideFilterVisible(false)
  }

  return (
    <>
      <SectionHeader
        pageHeading="Dashboard"
        handleDrawerOpen={handleDrawerOpen}
        handleShowSideFilters={handleShowSideFilters}
        handleHideSideFilters={handleHideSideFilters}
        sideFilterVisible={sideFilterVisible}
      />
      <Layout.Body data-testid="homePage" includeRail>
        <DashboardSection sideFilterVisible={sideFilterVisible} />
        <Drawer
          isVisible={isDrawerOpen}
          onRequestClose={() => {
            setIsDrawerOpen(false)
          }}
          headingText="Submit a Invoice"
          xs={6}
        >
          <SubmitInvoiceWrapper handleDrawerClose={handleDrawerClose} />
        </Drawer>
      </Layout.Body>
    </>
  )
}
//TOODO api to return sorted data desc
