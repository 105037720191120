import {
  Form,
  Modal,
  Grid,
  Button,
  ToastProvider,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { useState, useEffect } from 'react'
import moment from 'moment'
import { useExemptionVendor } from '../../api/hooks/useExemptionVendor'
import { UpdateExemptionVendor } from '../../api/models/ExemptionVendor'
export const EditVendor = (props: any) => {
  const makeToast = ToastProvider.useToaster()
  const { getVendorDetailsFromId, updateVendor } = useExemptionVendor()
  const [vendorToBeUpdated, setVendorToBeUpdated] =
    useState<UpdateExemptionVendor>({})

  useEffect(() => {
    if (props.isVisible === true) {
      getVendorDetailsFromId(props.id)
        .then((response: any) => {
          setVendorToBeUpdated({
            ...vendorToBeUpdated,
            vendorNumber: response.vendorNumber,
            vendorName: response.vendorName,
            ediStatus: response.ediStatus,
            active: response.active,
            permanentExemptFlag: response.permanentExemptFlag,
            exemptEndDate: response.exemptEndDate ?? '',
            exemptStartDate: response.exemptStartDate ?? '',
          })
        })
        .catch((error: any) => console.log(error))
    }
  }, [props.isVisible, props.id, getVendorDetailsFromId, vendorToBeUpdated])

  const [, setEnableDateFieldUpdate] = useState<Boolean>(
    !vendorToBeUpdated.permanentExemptFlag
  )

  return (
    <Modal
      isVisible={props.isVisible}
      onRefuse={() => {
        props.setShowUpdateScreen(false)
        setVendorToBeUpdated({})
      }}
      headingText="Edit Vendor"
    >
      <Grid.Container justify={'center'} className="hc-pa-normal">
        <Grid.Item xs={11}>
          <Grid.Container>
            <Grid.Item xs={4}>
              <Input.Label>Vendor Number</Input.Label>
              <Form.Field
                id="vendorNumber"
                disabled
                value={vendorToBeUpdated.vendorNumber}
              />
            </Grid.Item>
            <Grid.Item xs={7}>
              <Input.Label>Vendor Name</Input.Label>
              <Form.Field
                id="vendorName"
                disabled
                value={vendorToBeUpdated.vendorName}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        {!vendorToBeUpdated.permanentExemptFlag && (
          <Grid.Item xs={11}>
            <Grid.Container align="center">
              <Grid.Item xs={5}>
                <Form.Field
                  id="exemptStartDate"
                  label="Exemption Beginning"
                  onChange={(e: any) => {
                    setVendorToBeUpdated({
                      ...vendorToBeUpdated,
                      exemptStartDate: e.target.value,
                    })
                  }}
                  value={vendorToBeUpdated.exemptStartDate}
                  type="date"
                />
              </Grid.Item>
              <Grid.Item xs={6}>
                <Form.Field
                  id="exemptEndDate"
                  label="Exemption Terminating"
                  onChange={(e: any) => {
                    setVendorToBeUpdated({
                      ...vendorToBeUpdated,
                      exemptEndDate: e.target.value,
                    })
                  }}
                  value={vendorToBeUpdated.exemptEndDate}
                  type="date"
                />
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        )}
        <Grid.Item xs={11}>
          <Grid.Container align="center">
            <Grid.Item xs={5}>
              <Input.Toggle
                id="permanentExempt"
                label="Permanent Exemption"
                onChange={(event: any) => {
                  setEnableDateFieldUpdate(!event.target.checked)
                  setVendorToBeUpdated({
                    ...vendorToBeUpdated,
                    permanentExemptFlag: event.target.checked,
                    exemptStartDate: event.target.checked
                      ? ''
                      : vendorToBeUpdated.exemptStartDate,
                    exemptEndDate: event.target.checked
                      ? ''
                      : vendorToBeUpdated.exemptEndDate,
                  })
                  console.log('updated vendor', vendorToBeUpdated)
                }}
                // type="toggle"
                checked={vendorToBeUpdated.permanentExemptFlag}
                required
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item xs={12}>
          <Grid.Container direction="row-reverse" spacing="dense">
            <Grid.Item>
              <Button
                type="submit"
                onClick={() => {
                  const currentDateTime = moment()
                  const formattedDateTime = currentDateTime.format(
                    'YYYY-MM-DDTHH:mm:ss.SSS'
                  )
                  let auditEntry = {
                    activityDescription: 'updated vendor',
                    activityTimestamp: formattedDateTime,
                    userId: 'admin',
                    orderNumber: 1,
                    comment: '',
                  }
                  const newVendor = {
                    ...vendorToBeUpdated,
                    auditEntry: auditEntry,
                  }
                  setVendorToBeUpdated({
                    ...vendorToBeUpdated,
                    auditEntry: auditEntry,
                  })
                  console.log('vendor submitted', newVendor)
                  updateVendor(props.id, newVendor)
                    .then((response: any) => {
                      props.setShowUpdateScreen(false)
                      makeToast({
                        autoHideDuration: 5000,
                        type: 'success',
                        heading: 'Successfully updated!',
                        message: 'Your request has been updated.',
                      })
                      window.location.reload()
                    })
                    .catch((error: any) => {
                      makeToast({
                        autoHideDuration: 5000,
                        type: 'error',
                        heading: 'Something went wrong!!',
                        message: 'Your request was not updated.',
                      })
                    })
                  setVendorToBeUpdated({})
                }}
              >
                Submit
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                onClick={() => {
                  props.setShowUpdateScreen(false)
                  setVendorToBeUpdated({})
                }}
              >
                Cancel
              </Button>
            </Grid.Item>
            <Grid.Item></Grid.Item>
            <Grid.Item>
              <Form.Field
                id="active"
                type="toggle"
                label={'Active'}
                value={vendorToBeUpdated.active}
                onChange={(event: any) => {
                  console.log('update active', event.target.checked)
                  setVendorToBeUpdated({
                    ...vendorToBeUpdated,
                    active: !vendorToBeUpdated.active,
                  })
                  console.log('Toggle value:', vendorToBeUpdated.active)
                }}
              />
            </Grid.Item>
          </Grid.Container>
          <Grid.Container spacing="dense"></Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </Modal>
  )
}
