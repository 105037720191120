import { Grid, Layout, Heading } from '@enterprise-ui/canvas-ui-react'
import { ReportSection, ReportSectionProps } from './ReportSection'

export const ReportsList = () => {
  const reports: ReportSectionProps[] = [
    {
      title: 'Aggregation',
      cards: [
        {
          cardTitle: 'Aggregation By Vendor and Status',
          url: 'aggregated_reports/aggregateBy=vendorName,status',
        },
        {
          cardTitle: 'Aggregation by Vendor and Purchase Order Number',
          url: 'aggregated_reports/aggregateBy=vendorName,purchaseOrderNumber',
        },
        {
          cardTitle: 'Aggregation by Vendor and Invoice Date',
          url: 'aggregated_reports/aggregateBy=vendorName,invoiceDate',
        },
        // {
        //   cardTitle: 'Aggregation by Vendor Number and Invoice Date',
        //   url: 'aggregated_reports/aggregateBy=vendorNumber,invoiceDate',
        // },
        // {
        //   cardTitle: 'Aggregation by Vendor Number and Status',
        //   url: 'aggregated_reports/aggregateBy=vendorNumber,status',
        // },
        // {
        //   cardTitle: 'Aggregation by Vendor Number and Purchase Order Number',
        //   url: 'aggregated_reports/aggregateBy=vendorNumber,purchaseOrderNumber',
        // },
      ],
    },
    //   {
    //     title: 'Aggregation by Sum',
    //     cards: [
    //       {
    //         cardTitle: 'Sum By Status',
    //         url:'aggregated_reports'
    //       },
    //       {
    //         cardTitle: 'Sum By Vendor',
    //         url:'aggregated_reports'
    //       }
    //     ]
    //   }
  ]

  return (
    <>
      <Layout.SectionHeader>
        <Grid.Container className="hc-pb-none">
          <Heading size={2} style={{ padding: '10px' }}>
            Reports
          </Heading>
        </Grid.Container>
      </Layout.SectionHeader>
      <Layout.Body includeRail>
        {reports.map((report) => (
          <ReportSection
            key={report.title}
            title={report.title}
            cards={report.cards}
          />
        ))}
      </Layout.Body>
    </>
  )
}
