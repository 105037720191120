import React, { useEffect, useState } from 'react'
import {
  Button,
  Input,
  Popover,
  Tabs,
  Grid,
} from '@enterprise-ui/canvas-ui-react'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-datepicker'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import _ from 'lodash'
import { useNavigate, useLocation } from 'react-router-dom'
import moment from 'moment'

export const RangeFilter = (props: any) => {
  let startDateLabel = ''
  let endDateLabel = ''
  const aggParams = props.aggParams
  const [activeTab, setActiveTab] = useState('tab_01')
  const [activeFilterLabel, setActiveFilterLabel] = useState<string>('All Time')
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const navigate = useNavigate()
  const location = useLocation()
  let aggTemp = ''
  let aggregate = location.search?.split('aggregateBy=')[1] ?? '' // to get the part after aggregteBy in url
  console.log('aggregate', aggregate)
  if (aggregate.includes('invoiceDateFrom')) {
    aggTemp = aggregate?.split('&invoiceDateFrom')[0] ?? ''
  } else {
    aggTemp = aggregate
  }

  useEffect(() => {
    console.log('time loc', location)
    console.log('aggTemp', aggTemp)
    if (!aggregate.includes('invoiceDateFrom')) {
      setActiveFilterLabel('All Time')
    } else {
      let temp1 = aggregate?.split('invoiceDateFrom=')[1] ?? ''
      let start = temp1?.split('&invoiceDateTo=')[0] ?? ''
      let end = temp1?.split('&invoiceDateTo=')[1] ?? ''
      const starting = moment(start, 'MM-DD-YYYY')
      const ending = moment(end, 'MM-DD-YYYY')

      const timeTemp = ending.diff(starting, 'days')
      console.log('timediff', timeTemp)

      if (timeTemp === 7) {
        setActiveFilterLabel('Last 7 days')
      }
      if (timeTemp === 30) {
        setActiveFilterLabel('Last 30 days')
      }
      if (timeTemp === 365) {
        setActiveFilterLabel('Last 1 year')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  function handleSubmitButton() {
    navigate(
      `/reports/aggregated_reports/filter?aggregateBy=${aggParams}&invoiceDateFrom=${startDate}&invoiceDateTo=${endDate}`
    )
  }
  function handleRadioChange(value: any) {
    let start = ''
    let end = ''
    if (value === '7d') {
      end = moment().format('MM-DD-YYYY')
      start = moment().subtract(7, 'days').format('MM-DD-YYYY')
    } else if (value === '30d') {
      end = moment().format('MM-DD-YYYY')
      start = moment().subtract(30, 'days').format('MM-DD-YYYY')
    } else if (value === '365d') {
      end = moment().format('MM-DD-YYYY')
      start = moment().subtract(365, 'days').format('MM-DD-YYYY')
    }
    console.log('end', end)
    console.log('start', start)
    navigate(
      `/reports/aggregated_reports/filter?aggregateBy=${aggParams}&invoiceDateFrom=${start}&invoiceDateTo=${end}`
    )
  }

  return (
    <Popover
      size="expanded"
      content={
        <div>
          <Tabs
            activeTab={activeTab}
            onTabSelect={(event: any, tab: any) => {
              setActiveTab(tab.name)
            }}
          >
            <Tabs.Item name="tab_01">Quick Filters</Tabs.Item>
            <Tabs.Item name="tab_02">Custom Range</Tabs.Item>
            <Tabs.Content name="tab_02">
              <Grid.Container xs={40} justify="flex-end" direction="row">
                <Grid.Item
                  style={{ alignSelf: 'center' }}
                  className="hc-pb-none"
                >
                  <DatePicker
                    id={`start_date`}
                    label="Start Date"
                    placeholder="MM/DD/YYYY"
                    onUpdate={(id, value) => {
                      startDateLabel = value
                      let parts = value.split('/')
                      let date = `${parts[0]}-${parts[1]}-${parts[2]}`
                      setStartDate(date)
                      console.log('startdate', value)
                    }}
                    value={startDateLabel ?? ''}
                    alignCalendarDropdown="right"
                    type="gregorian"
                  />
                </Grid.Item>
                <Grid.Item
                  style={{ alignSelf: 'center' }}
                  className="hc-pb-none"
                >
                  <DatePicker
                    id={`end_date`}
                    label="Stop Date"
                    placeholder="MM/DD/YYYY"
                    onUpdate={(id, value) => {
                      endDateLabel = value
                      let parts = value.split('/')
                      let date = `${parts[0]}-${parts[1]}-${parts[2]}`
                      setEndDate(date)
                      console.log('enddate', value)
                    }}
                    value={endDateLabel ?? ''}
                    alignCalendarDropdown="right"
                    type="gregorian"
                  />
                </Grid.Item>
                <Grid.Item className="hc-pt-none">
                  <Button
                    onClick={() => {
                      setActiveFilterLabel('Custom Dates')
                      handleSubmitButton()
                    }}
                    disabled={_.isEmpty(startDate) || _.isEmpty(endDate)}
                  >
                    Apply
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Tabs.Content>
            <Tabs.Content name="tab_01">
              <Input.Radio
                id="time_span"
                options={[
                  { value: '7d', label: 'Last 7 days' },
                  { value: '30d', label: 'Last 30 days' },
                  { value: '365d', label: 'Last 1 year' },
                ]}
                onUpdate={(id: any, value: any) => {
                  if (value) {
                    handleRadioChange(value)
                  }
                }}
              ></Input.Radio>
            </Tabs.Content>
          </Tabs>
        </div>
      }
      openOn="clickOnly"
      location="bottom-right"
      width="lg"
      padding="none"
    >
      <Button type="secondary" outlined>
        From: {activeFilterLabel}
      </Button>
    </Popover>
  )
}
