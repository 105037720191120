import { useRef, useCallback } from 'react'
import { CreateDraftInvoice } from '../../api/models/Draft'

export function useScrollToInvoice(invoices: CreateDraftInvoice[]) {
  const itemRefs = useRef<Array<HTMLDivElement | null>>([])

  const setItemRef = useCallback(
    (element: HTMLDivElement | null, index: number) => {
      itemRefs.current[index] = element
    },
    []
  )

  const scrollToItem = useCallback((index: number) => {
    const item = itemRefs.current[index]
    if (item) {
      item.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [])

  return [setItemRef, scrollToItem] as const
}
