import _ from 'lodash'
import { DashboardAction, DashboardState } from './DashboardContext'

export const reducer = (
  state: DashboardState,
  action: DashboardAction
): DashboardState => {
  switch (action.type) {
    case 'SET_CARD_DATA':
      return {
        ...state,
        cards: action.cards,
      }
    case 'SET_TABLE_DATA':
      return { ...state, invoiceData: [...action.data] }
    case 'SET_FILTERS':
      return { ...state, filters: action.filters }
    case 'SET_DEFAULT_SIDE_FILTERS_VALUES':
      state?.filters?.sideFilters?.forEach((filter) => {
        if (action.values[filter.id] !== undefined) {
          filter.values = action?.values[filter.id]
        }
      })
      return { ...state }
    case 'UPDATE_FILTERS':
      state?.filters?.sideFilters
        ?.filter((filter) => filter.type === 'checkbox')
        .forEach((filter) => {
          const selectedValues = action.activeFilters[filter.id]
          filter.values?.forEach((value) => {
            value.isChecked = _.includes(selectedValues, value.id)
          })
        })

      //date range
      state?.filters?.sideFilters
        ?.filter((filter) => filter.type === 'date')
        .forEach((filter) => {
          filter.active = action.activeFilters[filter.id] ?? null
        })

      //radio
      state?.filters?.sideFilters
        ?.filter((filter) => filter.type === 'radio')
        .forEach((filter) => {
          const selectedValue = action.activeFilters[filter.id]
          filter.values?.forEach((value) => {
            value.isChecked = selectedValue === value.id
          })
        })

      //search group
      state?.filters?.sideFilters
        ?.filter((filter) => filter.type === 'search_group')
        .forEach((filter) => {
          const searchFilters = action.activeFilters
          const searchFilterKeys = Object.keys(searchFilters ?? {})
          filter.searchFields?.forEach((field) => {
            if (_.includes(searchFilterKeys, field.field)) {
              field.values = searchFilters[field.field]?.map(
                (value: string) => ({
                  id: value,
                  value: value,
                  label: value,
                })
              )
            } else {
              field.values = []
            }
          })
        })
      // main search
      if (action.activeFilters['search'] != null) {
        const searchFilters: any = []
        Object.keys(action.activeFilters['search']).forEach((value: any) => {
          searchFilters.push({
            id: value,
            value: action.activeFilters['search'][value],
            label: action.activeFilters['search'][value],
          })
        })
        let filters: any = state?.filters ?? {}
        filters['searchFilters'] = _.uniqBy(searchFilters, 'id')
        state = { ...state, filters: filters }
      } else {
        let filters: any = state?.filters ?? {}
        filters['searchFilters'] = []
        state = { ...state, filters: filters }
      }
      return { ...state }

    default:
      return { ...state }
  }
}
