import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../configs/apiConfig'
import axios from 'axios'
import {
  InvoiceResponse,
  NewInvoiceRequest,
  UpdateInvoiceRequest,
} from '../models/InvoiceResponse'
import _ from 'lodash'

export const useInvoice = () => {
  const env = useEnv() as EnvConfig

  const getAllInvoices = async (): Promise<InvoiceResponse[]> => {
    const res = await axios.get(`${env.baseApiUrl}/invoice/all`)
    return res.data
  }
  const getInvoice = async (id: any): Promise<InvoiceResponse> => {
    const res = await axios.get(`${env.baseApiUrl}/invoice/${id}`)
    return res.data
  }

  const getInvoicesForAppliedFilters = async (filtersApplied: any = {}) => {
    let formattedFilters = _.omitBy(filtersApplied, (v) => v == null)
    let urlParam = Object.entries(formattedFilters)
      .map((e) => e.join('='))
      .join('&')
    const res = await axios.get(`${env.baseApiUrl}/invoice?${urlParam}`)
    return res.data
  }

  const updateInvoice = async (
    id: any,
    invoice: UpdateInvoiceRequest
  ): Promise<InvoiceResponse[]> => {
    const res = await axios.put(`${env.baseApiUrl}/invoice/${id}`, invoice)
    return res.data
  }

  const postInvoice = (
    newInvoiceRequest: NewInvoiceRequest
  ): Promise<InvoiceResponse> => {
    return axios
      .post(`${env.baseApiUrl}/invoice/`, newInvoiceRequest)
      .then((res) => Promise.resolve(res.data))
  }

  const getReport = async (params: string) => {
    const res = await axios.get(`${env.baseApiUrl}/invoice/report?${params}`)
    return res.data
  }

  return {
    getInvoice,
    getAllInvoices,
    getInvoicesForAppliedFilters,
    updateInvoice,
    postInvoice,
    getReport,
  }
}
