import { Grid, ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { Gallery } from '@enterprise-ui/canvas-ui-react-gallery'
import React, { useEffect, useState } from 'react'
import { useAttachment } from '../../api/hooks/useAttachments'
import LoadingSpinner from '../../globalComponents/LoadingSpinner'
import fileDownload from 'js-file-download'

interface Props {
  invcId?: string
}

export const AttachmentsGallery: React.FC<Props> = ({ invcId }) => {
  const { getAttachmentsByRequestId, getAttachmentById } = useAttachment()
  const [urls, setUrls] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const makeToast = ToastProvider.useToaster()

  const getFileType = (fileName: any) => {
    return fileName.slice(fileName.lastIndexOf('.') + 1, fileName.length)
  }

  function fetchAndFormatAttachments() {
    setLoading(true)
    getAttachmentsByRequestId(invcId)
      .then((files: any[]) => {
        let filesData = files?.map((file) => ({
          id: file?.attachment_id,
          type: getFileType(file?.file_name),
          name: file?.file_name,
        }))

        const promises = filesData?.map((file) => {
          return getAttachmentById(file?.id)
        })

        Promise.all(promises)
          .then(async (attachments) => {
            let attachmentURLs: any = []
            for (let i = 0; i < attachments?.length; i++) {
              if (attachments[i].status === 200) {
                let metaData = filesData.filter(
                  (data) =>
                    data?.id === attachments[i].config?.url?.split('/files/')[1]
                )[0]

                let file = {
                  data: attachments[i].data,
                  type: metaData?.type,
                  name: metaData?.name,
                  id: metaData?.id,
                }
                const fileType = file?.type?.toLowerCase()
                if (fileType === 'pdf') {
                  const url = window.URL.createObjectURL(
                    new Blob([file?.data], { type: 'application/pdf' })
                  )
                  attachmentURLs.push({
                    mediaType: 'PDF',
                    id: file?.id,
                    url: url,
                    name: file?.name,
                  })
                } else if (
                  fileType === 'png' ||
                  fileType === 'jpg' ||
                  fileType === 'jpeg'
                ) {
                  const url = window.URL.createObjectURL(
                    new Blob([file.data], { type: 'image/png' })
                  )
                  attachmentURLs.push({
                    mediaType: '',
                    id: file?.id,
                    url: url,
                    name: file?.name,
                  })
                } else if (fileType === 'csv') {
                  const url = window.URL.createObjectURL(
                    new Blob([file.data], { type: 'text/csv' })
                  )
                  attachmentURLs.push({
                    mediaType: '',
                    id: file?.id,
                    url: url,
                    name: file?.name,
                  })
                } else {
                  attachmentURLs.push({
                    mediaType: 'PDF',
                    id: file?.id,
                    url: '',
                    name: file?.name,
                  })
                }
              }
            }
            setUrls(attachmentURLs)
            setLoading(false)
          })
          .catch((error) => {
            console.log('error in fetching docs for invc', error)
          })
        setError(false)
      })
      .catch((err) => {
        setLoading(false)
        setError(true)
        makeToast({
          autoHideDuration: 4000,
          type: 'error',
          heading: 'Error while fetching attachments',
          message: 'Make sure you are connected to network or try again later',
        })
      })
  }
  useEffect(() => {
    fetchAndFormatAttachments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDownload = async (file: any) => {
    let blob = await fetch(file?.url).then((r) => r.blob())
    fileDownload(blob, file?.name)
  }

  return (
    <Grid.Item xs className="hc-pa-xl">
      {loading && <LoadingSpinner />}
      {!loading && urls?.length > 0 && (
        <Gallery
          mainItemHeight="70vh"
          mainItemStartIndex={0}
          onDownload={(e) => handleDownload(e)}
          items={[...urls].map((file: any) => ({
            alt: file?.name,
            mediaType: file?.mediaType,
            name: file?.name,
            url: file?.url,
            thumbUrl: file?.url,
          }))}
        />
      )}
      {!loading && (error || urls?.length === 0) && (
        <Gallery
          mainItemHeight="70vh"
          mainItemStartIndex={0}
          items={[
            {
              thumbUrl: null,
              url: null,
            },
          ]}
        />
      )}
    </Grid.Item>
  )
}
//uplaod limit/file type errror, create if first file fails, it wont proceed
