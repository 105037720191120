import axios from 'axios'
import {
  Vendor,
  VendorResponse,
} from '../../context/UserProfile/UserProfileContext'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../configs/apiConfig'

export const useVendorApi = () => {
  const env = useEnv() as EnvConfig
  const getVendorList = (businessPartnerId: string): Promise<Vendor[]> => {
    return axios
      .get(`${env.baseApiUrl}/vendors/`, {
        params: {
          business_partner_id: businessPartnerId,
        },
      })
      .then((res) =>
        Promise.resolve(
          res.data.map((vendor: any) => ({
            vendorId: Number(vendor.gmsVendorNumber),
            vendorName: vendor.vendorName,
          }))
        )
      )
  }

  const getVendorDetails = async (
    vendorNumber: string
  ): Promise<VendorResponse> => {
    const res = await axios.get(`${env.baseApiUrl}/vendors/${vendorNumber}`)
    return res.data
  }

  return { getVendorList, getVendorDetails }
}
