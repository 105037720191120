import { Routes, Route, useLocation } from 'react-router-dom'
import { ErrorBoundary } from '@praxis/component-logging'

import { ErrorContent } from './ErrorContent'
import { Dashboard } from '../views/Dashboard'
import { InvoiceDetail } from '../views/InvoiceDetail/index'
import { DashboardProvider } from '../context/Invoices/DashboardProvider'
import { ReportsList } from '../views/Reports/ReportsList'
import { ReportsTable } from '../views/Reports/ReportsTable'
import { Bulk } from '../views/Bulk'
import DraftProvider from '../views/Bulk/BulkProvider'
import { ExemptionVendorList } from '../views/ExemptionVendor/ExemptionVendorList'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { QueryClient, QueryClientProvider } from 'react-query'

export const MainRouter: React.FC = () => {
  const location = useLocation()
  const queryClient = new QueryClient()
  return (
    <ErrorBoundary key={location.pathname} FallbackComponent={ErrorContent}>
      <ToastProvider location="top">
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route
              path="/invoices"
              element={
                <DashboardProvider>
                  <Dashboard />
                </DashboardProvider>
              }
            />
            <Route
              path="/"
              element={
                <DashboardProvider>
                  <Dashboard />
                </DashboardProvider>
              }
            />
            <Route
              path="/bulk-invoices"
              element={
                <DraftProvider>
                  <Bulk />
                </DraftProvider>
              }
            />
            <Route path="/view/:invoiceId" element={<InvoiceDetail />} />
            <Route path="/reports" element={<ReportsList />} />
            <Route
              path="/reports/aggregated_reports/:id"
              element={<ReportsTable />}
            />
            <Route path="/admin" element={<ExemptionVendorList />} />
          </Routes>
        </QueryClientProvider>
      </ToastProvider>
    </ErrorBoundary>
  )
}
