import {
  Button,
  Dropdown,
  Grid,
  Heading,
  Layout,
} from '@enterprise-ui/canvas-ui-react'
import {
  CaretLeftIcon,
  CaretRightIcon,
  EnterpriseIcon,
  PlusIcon,
} from '@enterprise-ui/icons'
import { useUserProfile } from '../context/UserProfile/UserProfileProvider'
import { Role } from '../context/UserProfile/UserProfileContext'
import { useNavigate } from 'react-router-dom'

interface SectionHeaderProp {
  pageHeading: any
  handleDrawerOpen?: any
  handleShowSideFilters?: any
  handleHideSideFilters?: any
  sideFilterVisible?: any
  filters?: boolean
}
export const SectionHeader: React.FC<SectionHeaderProp> = ({
  pageHeading,
  handleDrawerOpen,
  handleShowSideFilters,
  handleHideSideFilters,
  sideFilterVisible,
  filters = true,
}) => {
  const [userProfile] = useUserProfile()
  const navigate = useNavigate()

  const isAllowedToCreate = () => {
    return userProfile?.isVendor && userProfile?.userRole === Role.VENDOR
  }
  return (
    <Layout.SectionHeader>
      <div className="hc-pa-dense hc-pb-none">
        <Grid.Container spacing="dense" align="center" direction="row-reverse">
          {filters && (
            <Grid.Item>
              <Button
                type="primary"
                onClick={() =>
                  sideFilterVisible
                    ? handleHideSideFilters()
                    : handleShowSideFilters()
                }
              >
                <EnterpriseIcon
                  icon={sideFilterVisible ? CaretRightIcon : CaretLeftIcon}
                />
                {sideFilterVisible ? 'Hide Filters' : 'Show Filters'}
              </Button>
            </Grid.Item>
          )}
          {handleDrawerOpen && isAllowedToCreate() && (
            <>
              <Dropdown>
                <Button type="primary">
                  <EnterpriseIcon icon={PlusIcon} />
                  New Invoice
                </Button>
                <Dropdown.Menu location="bottom-right">
                  <Dropdown.MenuItem onClick={() => handleDrawerOpen()}>
                    Quick Invoice
                  </Dropdown.MenuItem>
                  <Dropdown.MenuItem onClick={() => navigate('/bulk-invoices')}>
                    Bulk Invoices
                  </Dropdown.MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}

          {pageHeading && (
            <Grid.Item xs>
              <Heading data-testid="sectionHeaderHeading" size={4}>
                {pageHeading}
              </Heading>
            </Grid.Item>
          )}
        </Grid.Container>
      </div>
    </Layout.SectionHeader>
  )
}
