import React, { useState, useEffect } from 'react'
import {
  Button,
  Caption,
  Card,
  Form,
  Grid,
  GridContainer,
  Input,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  FileIcon,
  TrashIcon,
  UploadIcon,
} from '@enterprise-ui/icons'
import { useUserProfile } from '../../context/UserProfile/UserProfileProvider'
import { convertForSelectOption } from '../submitInvoice/utils'
import { CreateDraftInvoice } from '../../api/models/Draft'
import { useDrafts } from './BulkProvider'
import { useAuth } from '@praxis/component-auth'
import {
  calculateInvoiceAmount,
  formatInvoiceForm,
  formatNewInvoice,
  initialInvoiceValues,
} from './utils'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { AllowanceCodes } from '../../constants/AllowanceCodes'

interface SectionProps {
  index: number
  invc?: CreateDraftInvoice
  removeInvoiceByIndex?: any
}

export const InvoiceSection: React.FC<SectionProps> = ({ index, invc }) => {
  const { session } = useAuth()

  const invoice =
    invc !== undefined ? formatInvoiceForm(invc?.invoice) : initialInvoiceValues
  const [localInvoice, setLocalInvoice] = useState(invoice)
  const [tempAllowance, setTempAllowance] = useState<any>({
    allowanceCode: undefined,
    allowanceAmount: 0,
  })

  const [userProfile] = useUserProfile()
  const [state, dispatch] = useDrafts()

  const hasAllowance =
    localInvoice.allowanceCosts && localInvoice.allowanceCosts?.length >= 1
  const [allowanceCostCheck, setAllowanceCostCheck] = useState(hasAllowance)
  const errors = state.errors?.findLast(
    (errors) => errors.index === index
  )?.errors

  const invoiceAmount = calculateInvoiceAmount(
    localInvoice.productCost,
    localInvoice.allowanceCosts
  )
  const getVendors = () => {
    return userProfile.vendorList
      ?.map((vendor) => vendor.vendorId)
      .map(convertForSelectOption)
  }

  const handleVendorNumberChange = async (id: string, value: any) => {
    const vendorName = userProfile.vendorList?.find(
      (vendor) => vendor.vendorId === value
    )?.vendorName

    setLocalInvoice({
      ...localInvoice,
      vendorNumber: value,
      vendorEmailAddress: session?.userInfo?.email!!,
      vendorContactName: session?.userInfo?.fullName!!,
      vendorName: vendorName!!,
    })
  }

  const handleChange = (field: any, value: any) => {
    setLocalInvoice({
      ...localInvoice,
      [field]: value.target.value,
    })
  }

  const aocCodeOptions = AllowanceCodes.map((item) => ({
    id: item.code,
    value: `${item.code} - ${item.label}`,
    label: `${item.code} - ${item.label}`,
  }))

  const handleAddAllowanceCode = () => {
    const header = AllowanceCodes.find(
      (header) => header.code === tempAllowance.allowanceCode.id
    )
    const newAoc = {
      allowanceType: header!!.type,
      allowanceCode: tempAllowance.allowanceCode.value,
      allowanceAmount: tempAllowance.allowanceAmount,
    }
    setLocalInvoice({
      ...localInvoice,
      allowanceCosts: [...(localInvoice.allowanceCosts ?? []), newAoc],
    })
    setTempAllowance({ allowanceCode: undefined, allowanceAmount: 0 })
  }

  const handleRemoveAllowanceCode = (index: number) => {
    const newAocCosts = localInvoice.allowanceCosts?.slice()
    newAocCosts?.splice(index, 1)

    setLocalInvoice({
      ...localInvoice,
      allowanceCosts: newAocCosts,
    })
  }

  const handleAllownceCheck = () => {
    setAllowanceCostCheck((state) => !state)
  }

  const isAddButtonEnabled = () => {
    return (
      tempAllowance.allowanceCode !== undefined &&
      tempAllowance.allowanceAmount > 0
    )
  }

  useEffect(() => {
    if (initialInvoiceValues !== localInvoice) {
      const updatedDraftInvoices = [...state.draftInvoices]
      if (index < updatedDraftInvoices.length) {
        updatedDraftInvoices[index] = {
          ...updatedDraftInvoices[index],
          invoice: formatNewInvoice(localInvoice),
        } as CreateDraftInvoice
      } else {
        updatedDraftInvoices.push({
          invoice: formatNewInvoice(localInvoice),
        } as CreateDraftInvoice)
      }

      dispatch({
        type: 'SET_DRAFT_INVOICES',
        payload: { draftInvoices: updatedDraftInvoices },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInvoice])

  const handleFileInput = (event: any) => {
    const file = event.target.files[0]
    if (file) {
      handleAddAttachment(index, { file: file, name: file.name })
    }
  }

  const handleRemoveInvoice = () => {
    const updatedDraftInvoices = state.draftInvoices.filter(
      (_, idx) => idx !== index
    )
    dispatch({
      type: 'SET_DRAFT_INVOICES',
      payload: { draftInvoices: updatedDraftInvoices },
    })
  }

  const handleAddAttachment = (index: number, file: any) => {
    dispatch({ type: 'ADD_ATTACHMENT', payload: { index, file } })
  }

  const handleRemoveAttachment = (index: number) => {
    dispatch({ type: 'REMOVE_ATTACHMENT', payload: { index } })
  }

  return (
    <Card className="hc-pa-md hc-ph-xl bulkCard">
      <Button
        className="bulkCloseButton"
        iconOnly
        aria-label="remove"
        type="ghost"
        disabled={index === 0}
        onClick={() => {
          handleRemoveInvoice()
        }}
      >
        <EnterpriseIcon icon={TrashIcon} />
      </Button>
      <Grid.Container align={'centre'} spacing={'dense'}>
        <Grid.Item xs={2}>
          <Form.Field
            type="select"
            id={index + 'vendorNumber'}
            label={'Vendor Number'}
            options={getVendors()}
            onUpdate={handleVendorNumberChange}
            value={localInvoice.vendorNumber}
            required
            error={errors?.vendorNumber ? true : false}
            errorText={errors?.vendorNumber}
          />
        </Grid.Item>
        <Grid.Item xs={2}>
          <Form.Field
            type="text"
            id="vendorName"
            label={'Vendor Name'}
            value={localInvoice.vendorName}
            readOnly
            error={errors?.vendorName ? true : false}
            errorText={errors?.vendorName}
            required
          />
        </Grid.Item>

        <Grid.Item xs={2}>
          <Form.Field
            id={index + 'vendorEmailAddress'}
            label="Vendor Email"
            type="text"
            value={localInvoice.vendorEmailAddress}
            readOnly
            error={errors?.vendorEmailAddress ? true : false}
            errorText={errors?.vendorEmailAddress}
            required
          />
        </Grid.Item>
        <Grid.Item xs={2}>
          <Form.Field
            id={index + 'invoiceNumber'}
            label="Invoice Number"
            type="text"
            value={localInvoice?.invoiceNumber}
            onChange={(value: any) => {
              handleChange('invoiceNumber', value)
            }}
            error={errors?.invoiceNumber ? true : false}
            errorText={errors?.invoiceNumber}
            required
          />
        </Grid.Item>
        <Grid.Item xs={2}>
          <Form.Field
            id={index + 'purchaseOrderNumber'}
            label="Purchase Order"
            type="text"
            value={localInvoice?.purchaseOrderNumber}
            onChange={(value: any) => {
              handleChange('purchaseOrderNumber', value)
            }}
            error={errors?.purchaseOrderNumber ? true : false}
            errorText={errors?.purchaseOrderNumber}
            required
          />
        </Grid.Item>
        <Grid.Item xs={1}>
          <Form.Field
            id={index + 'locationId'}
            label="Location"
            type="text"
            value={localInvoice?.locationId}
            onChange={(value: any) => {
              handleChange('locationId', value)
            }}
            error={errors?.locationId ? true : false}
            errorText={errors?.locationId}
            required
          />
        </Grid.Item>
        <Grid.Item xs={1}>
          <Form.Field
            id={index + 'departmentId'}
            label="Department"
            type="text"
            value={localInvoice.departmentId}
            onChange={(value: any) => {
              handleChange('departmentId', value)
            }}
            error={errors?.departmentId ? true : false}
            errorText={errors?.departmentId}
            required
          />
        </Grid.Item>
        <Grid.Item xs={2}>
          <Form.Field
            type="date"
            id="invoiceDate"
            label={'Invoice Date'}
            onChange={(value: any) => {
              handleChange('invoiceDate', value)
            }}
            value={localInvoice.invoiceDate}
            error={errors?.invoiceDate ? true : false}
            errorText={errors?.invoiceDate}
            required
          ></Form.Field>
        </Grid.Item>
        <Grid.Item xs={2}>
          <Form.Field
            id={index + 'productCost'}
            label="Product Cost"
            type="number"
            value={localInvoice?.productCost}
            onChange={(value: any) => {
              handleChange('productCost', value)
            }}
            error={errors?.productCost ? true : false}
            errorText={errors?.productCost}
            required
          />
        </Grid.Item>
        <Grid.Item xs={2}>
          <Form.Field
            id={index + 'invoiceAmount'}
            label="Total Cost"
            type="text"
            disabled
            value={invoiceAmount}
            error={errors?.invoiceAmount ? true : false}
            errorText={errors?.invoiceAmount}
          />
        </Grid.Item>

        <Grid.Item style={{ cursor: 'pointer' }} className="hc-mt-md">
          <Tooltip content="Upload Attachments" location="bottom">
            <div>
              <input
                type="file"
                id={`fileInput-${index}`}
                style={{ display: 'none' }}
                onChange={handleFileInput}
              />
              <Button
                onClick={() =>
                  document.getElementById(`fileInput-${index}`)?.click()
                }
                iconOnly
                aria-label="add attachment"
                type="secondary"
              >
                <EnterpriseIcon icon={UploadIcon} />
              </Button>
            </div>
          </Tooltip>
        </Grid.Item>
        <Grid.Item className="hc-mt-md">
          {state.attachments
            ?.filter((attachment) => attachment.index === index)
            ?.map((attachment: any) => (
              <Caption>
                <EnterpriseIcon
                  className="dropdownIcon"
                  icon={FileIcon}
                  size="lg"
                  onClick={() => {}}
                />{' '}
                &nbsp;
                {attachment?.file?.name} &emsp;
                <EnterpriseIcon
                  className="dropdownIcon"
                  icon={TrashIcon}
                  size={'sm'}
                  onClick={() => {
                    handleRemoveAttachment(index)
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Caption>
            ))}

          <Input.Label
            className="hc-pt-md"
            error={errors?.attachments ? true : false}
          >
            {errors?.attachments}
          </Input.Label>
        </Grid.Item>
      </Grid.Container>

      <GridContainer spacing="dense" align="center">
        <Grid.Item xs={3} className="hc-pb-lg">
          <Form.Field
            id={index + 'allowanceCheck'}
            label="have Additional Costs & Reductions?"
            type="checkbox"
            checked={allowanceCostCheck}
            onChange={handleAllownceCheck}
            disabled={hasAllowance}
          />
        </Grid.Item>
        {allowanceCostCheck && (
          <>
            <Grid.Item xs={4}>
              <Autocomplete
                id="aocCode"
                label="Choose One"
                onUpdate={(id, value) => {
                  setTempAllowance((prevState: any) => ({
                    ...prevState,
                    allowanceCode: value,
                  }))
                }}
                onEnter={() => {}}
                options={aocCodeOptions}
                value={tempAllowance.allowanceCode}
              />
            </Grid.Item>
            <Grid.Item xs={2}>
              <Form.Field
                type="number"
                id="newAllowanceAmount"
                label={'Allowance/Charge Amount'}
                onChange={(e: any) => {
                  setTempAllowance((prevState: any) => ({
                    ...prevState,
                    allowanceAmount: e.target.value,
                  }))
                }}
                value={tempAllowance.allowanceAmount}
                // ...other props
              />
            </Grid.Item>
            <Grid.Item xs={2} align>
              <Button
                onClick={() => {
                  handleAddAllowanceCode()
                }}
                disabled={!isAddButtonEnabled()}
              >
                Add
              </Button>
            </Grid.Item>
          </>
        )}
      </GridContainer>
      <GridContainer>
        {localInvoice.allowanceCosts?.map((allowanceCode, index) => (
          <Grid.Item xs={6} key={index}>
            <Grid.Container spacing="dense" align="center">
              <Grid.Item xs={6}>
                <Form.Field
                  type="text"
                  id={`allowanceCodes[${index}].code`}
                  label={'Allowance Code'}
                  value={allowanceCode.allowanceCode}
                  disabled
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Form.Field
                  type="number"
                  id={`allowanceCodes[${index}].amount`}
                  label={'Allowance/Charge Amount'}
                  value={allowanceCode.allowanceAmount}
                  disabled
                />
              </Grid.Item>
              <Grid.Item xs={2}>
                <Button onClick={() => handleRemoveAllowanceCode(index)}>
                  Remove
                </Button>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        ))}
      </GridContainer>
    </Card>
  )
}
