import { Caption, Grid, Spinner } from '@enterprise-ui/canvas-ui-react'

export default function LoadingSpinner() {
  return (
    <div>
      <Grid.Container className="hc-pt-xl hc-pb-lg" justify="center">
        <Grid.Item className="hc-ta-center">
          <Spinner size={'expanded'} />
        </Grid.Item>
        <Grid.Item className="hc-pt-lg hc-ta-center">
          <Caption>Loading ...</Caption>
        </Grid.Item>
      </Grid.Container>
    </div>
  )
}
