import { Grid, Form } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import React, { useState } from 'react'
import { SearchBarOptions } from '../../constants/SearchFilterInitialValues'
import { useNavigate, useLocation } from 'react-router-dom'
import { buildUrl } from '../../utils/SearchUtils'

export default function SearchBar() {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchField, setSearchField] = useState<string>(
    SearchBarOptions[0]?.value
  )

  const handleSearchSuggestion = (value: any) => {
    if (value && value.label) {
      navigate(
        '/invoices?' +
          buildUrl(location, {
            type: 'search',
            value: value.value,
            id: searchField,
          })
      )
    }
  }

  return (
    <React.Fragment>
      <Grid.Item xs={4} className="hc-pl-none hc-pt-min">
        <Autocomplete
          allowCustomInputValue
          id="search_bar"
          options={[]}
          errorText=""
          showSearchIcon
          onEnter={(event, value) => {}}
          onUpdate={(id, value) => {
            handleSearchSuggestion(value)
          }}
        />
      </Grid.Item>
      <Grid.Item className="hc-pr-none hc-pt-sm">
        <Form.Field
          id="search_bar_field_options"
          onUpdate={(id: any, value: any) => {
            setSearchField(value)
          }}
          options={SearchBarOptions ?? []}
          type="select"
          value={searchField}
        />
      </Grid.Item>
    </React.Fragment>
  )
}
