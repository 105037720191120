import { useCallback, useContext } from 'react'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../configs/apiConfig'
import axios from 'axios'
import { useAuth } from '@praxis/component-auth'
import {
  AttachmentMetadata,
  AttachmentResponse,
} from '../models/AttachmentResponse'
import {
  AttachmentDispatchContext,
  AttachmentStateContext,
} from '../../context/Attachments/AttachmentContext'

export const useAttachment = () => {
  const env = useEnv() as EnvConfig
  const auth = useAuth()
  const attachments = useContext(AttachmentStateContext)
  const { dispatch, upload, retryOnce } = useContext(AttachmentDispatchContext)

  const getAttachmentsByParentId = useCallback(
    (parentId: string | undefined) => {
      return attachments.filter(
        (attachment) => attachment.parentId === parentId
      )
    },
    [attachments]
  )

  const addAttachment = useCallback(
    async (
      name: string,
      parentId: string,
      file: Blob,
      attachmentMetaData: AttachmentMetadata,
      asyncUpload: boolean = true
    ) => {
      dispatch({
        type: 'ADD_ATTACHMENT',
        name,
        parentId,
        file,
        status: 'pending',
        asyncUpload,
        attachmentMetaData,
      })
    },
    [dispatch]
  )

  const addAttachments = useCallback(
    (
      attachments: {
        name: string
        parentId: string
        file: Blob
        attachmentMetaData: AttachmentMetadata
        asyncUpload: boolean
      }[]
    ) => {
      dispatch({
        type: 'ADD_ATTACHMENTS',
        attachments: attachments.map((attachment) => ({
          ...attachment,
          status: 'pending',
        })),
      })
    },
    [dispatch]
  )
  const removeAttachment = useCallback(
    (name: string, parentId: string) => {
      dispatch({ type: 'REMOVE_ATTACHMENT', name, parentId })
    },
    [dispatch]
  )

  const removeAttachments = useCallback(
    (parentId: string) => {
      const attachments = getAttachmentsByParentId(parentId)
      attachments.forEach((attachment) =>
        dispatch({ type: 'REMOVE_ATTACHMENT', name: attachment.name, parentId })
      )
    },
    [dispatch, getAttachmentsByParentId]
  )

  const retryAttachment = useCallback(
    (name: string, parentId: string) => {
      dispatch({ type: 'RETRY_ATTACHMENT', name, parentId })
    },
    [dispatch]
  )

  const uploadAttachment = async (
    metadata: AttachmentMetadata,
    file: Blob
  ): Promise<AttachmentResponse> => {
    const formData = new FormData()
    formData.append(
      'metadata',
      new Blob([JSON.stringify(metadata)], {
        type: 'application/text',
      })
    )
    formData.append('file', file)

    const res = await axios.post(
      `${env.attachmentApiUrl}/attachments`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return res.data
  }

  const getAttachmentsByRequestId = async (
    requestId?: string
  ): Promise<AttachmentResponse[]> => {
    const res = await axios.get(`${env.attachmentApiUrl}/attachments`, {
      params: { request_id: requestId },
    })
    return res.data
  }

  const getAttachmentById = async (attachmentId: string) => {
    return axios.get(
      `${env.attachmentApiUrl}/attachments/files/${attachmentId}`,
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-api-key': `${env?.apiKey}`,
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      }
    )
  }

  return {
    uploadAttachment,
    getAttachmentsByRequestId,
    getAttachmentById,
    attachments,
    upload,
    getAttachmentsByParentId,
    addAttachment,
    removeAttachment,
    retryAttachment,
    addAttachments,
    removeAttachments,
    retryOnce,
  }
}
