import {
  CreateDraftInvoice,
  Draft,
  InvoiceError,
  NewInvoice,
} from '../../api/models/Draft'
import { AllowanceCost } from '../../api/models/InvoiceResponse'
import { BulkInvoiceForm } from './BulkSchema'

export function formatNewInvoice(invoiceForm: BulkInvoiceForm): NewInvoice {
  return {
    invoiceNumber: invoiceForm.invoiceNumber,
    vendorNumber: invoiceForm.vendorNumber,
    vendorName: invoiceForm.vendorName,
    vendorContactName: invoiceForm.vendorContactName ?? '',
    vendorEmailAddress: invoiceForm.vendorEmailAddress,
    vendorType: invoiceForm.vendorType ?? '',
    purchaseOrderNumber: invoiceForm.purchaseOrderNumber,
    locationId: invoiceForm.locationId,
    departmentId: invoiceForm.departmentId,
    invoiceDate: invoiceForm.invoiceDate,
    invoiceAmount: calculateInvoiceAmount(
      invoiceForm.productCost,
      invoiceForm.allowanceCosts
    ),
    allowanceAmount: 0,
    status: 'New',
    auditEntry: {
      userId: 'Admin',
      activityDescription: 'New Case Created',
      comment: '',
      event: 'CREATE',
      orderNumber: 1,
      status: 'New',
      activityTimestamp: '2023-06-16T17:27:38.352251', //TODO: REMOVE
    },
    allowanceCosts: invoiceForm.allowanceCosts,
    productCost: invoiceForm.productCost,
  }
}

export function formatInvoiceForm(
  newInvoiceRequest: NewInvoice
): BulkInvoiceForm {
  return {
    invoiceNumber: newInvoiceRequest.invoiceNumber,
    vendorNumber: newInvoiceRequest.vendorNumber,
    vendorName: newInvoiceRequest.vendorName,
    vendorContactName: newInvoiceRequest.vendorContactName ?? '',
    vendorEmailAddress: newInvoiceRequest.vendorEmailAddress ?? '',
    vendorType: newInvoiceRequest.vendorType,
    purchaseOrderNumber: newInvoiceRequest.purchaseOrderNumber,
    locationId: newInvoiceRequest.locationId ?? '',
    departmentId: newInvoiceRequest.departmentId ?? 0,
    invoiceDate: newInvoiceRequest.invoiceDate ?? '',
    invoiceAmount: newInvoiceRequest.invoiceAmount ?? 0,
    isPOValid: false,
    allowanceCosts: newInvoiceRequest.allowanceCosts,
    productCost: newInvoiceRequest.productCost ?? 0,
  }
}

export function formatDraftInvoice(draft: Draft): CreateDraftInvoice[] {
  return (
    draft?.draftInvoices?.map((draftInvoice) => ({
      invoice: draftInvoice.invoice,
    })) ?? []
  )
}

export function calculateInvoiceAmount(
  productCost: number,
  aocCosts?: AllowanceCost[]
) {
  let calculatedInvoiceAmount = parseFloat(productCost.toString())

  aocCosts?.forEach((aoc) => {
    if (aoc.allowanceType === 'Allowance') {
      calculatedInvoiceAmount -= parseFloat(aoc.allowanceAmount.toString())
    } else if (aoc.allowanceType === 'Charge') {
      calculatedInvoiceAmount += parseFloat(aoc.allowanceAmount.toString())
    }
  })

  return parseFloat(calculatedInvoiceAmount.toFixed(2))
}

export function transformServerErrors(errors?: InvoiceError[]) {
  return errors?.reduce((acc: any, error) => {
    const key = error.field.replace('.', '') // Remove the dot from the field name
    acc[key] = error.errorMessage.toString()
    return acc
  }, {})
}

export function transformErrors(yupError: any, index: any) {
  const errorObject = yupError.inner.reduce((acc: any, error: any) => {
    const key = error.path.replace(/^\./, '')
    acc[key] = error.message
    return acc
  }, {})

  // Return errors with invoice index
  return { index, errors: errorObject }
}

export const initialInvoiceValues: BulkInvoiceForm = {
  invoiceNumber: '',
  vendorNumber: 0,
  vendorName: '',
  vendorContactName: '',
  vendorEmailAddress: '',
  vendorType: '',
  purchaseOrderNumber: 0,
  locationId: '',
  departmentId: 0,
  invoiceDate: '',
  invoiceAmount: 0,
  isPOValid: false,
  productCost: 0,
  allowanceCosts: [],
}
