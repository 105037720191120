import { Grid, Modal, Button } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { EnterpriseIcon, PersonIcon } from '@enterprise-ui/icons'
import React, { useState, useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import { useTeamMember } from '../../api/hooks/core/useTeamMember'
import { InvoiceResponse } from '../../api/models/InvoiceResponse'
import { ActionEvent, TeamMemberDetails } from '../../typedefintion'
import { useInvoice } from '../../api/hooks/useInvoice'

interface AssignProps {
  invcData: InvoiceResponse
  setInvcData: any
  setShowIndexedModal: any
  isIndexedOrRejected: any
}

export interface FormSelectOption {
  id: string
  value: string
  label: string
}

export const AssignTM: React.FC<AssignProps> = ({
  invcData,
  setInvcData,
  setShowIndexedModal,
  isIndexedOrRejected,
}) => {
  const { session } = useAuth()
  const [currentAssignedTm, setCurrentAssignedTm] = useState<any>()
  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false)
  const [tmData, setTMData] = useState([])
  const [assigneeOptions, setAssigneeOptions] = useState<FormSelectOption[]>()

  useEffect(() => {
    setAssigneeOptions(
      tmData?.map(function (tm: TeamMemberDetails) {
        return {
          id: tm.zid,
          value: tm.name,
          label: tm.name,
        }
      })
    )
  }, [tmData])

  const { getTmsByADgroup } = useTeamMember()
  const { updateInvoice } = useInvoice()

  useEffect(() => {
    getTmsByADgroup().then((response: any) => {
      setTMData(response)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSave = (updateInv: any) => {
    updateInvoice(invcData?.invoiceId, updateInv)
      .then((data) => {
        setInvcData(data)
      })
      .catch(() => {})
  }

  const handleTMAssignment = () => {
    let userName = session?.userInfo?.fullName
    let auditEntry = {
      userId: userName,
      activityDescription: `Invoice was assigned to ${currentAssignedTm?.label}`,
      comment: '',
      event: ActionEvent.UPDATE,
      status: invcData?.status,
      activityTimestamp: new Date(),
    }
    let updateInvc = Object.assign({}, invcData, {
      assignedUserId: currentAssignedTm?.id,
      assignedUserName: currentAssignedTm?.label,
      auditEntry: auditEntry,
    })
    handleSave(updateInvc)
  }

  const handleCloseAssignModal = () => {
    setIsAssignModalVisible(false)
    setCurrentAssignedTm(undefined)
  }

  return (
    <Grid.Item>
      <Modal
        isVisible={isAssignModalVisible}
        size="dense"
        headingText="Assign Invoice"
        onRefuse={() => {
          handleCloseAssignModal()
        }}
        style={{ overflowY: 'visible' }}
      >
        <Grid.Container className="hc-pa-md">
          <Grid.Item xs={12}>
            <Autocomplete
              id="assign_prf"
              options={assigneeOptions ?? []}
              label="Search by Assignee"
              onEnter={(event, value) => {
                setCurrentAssignedTm(value)
              }}
              onUpdate={(id, value) => {
                setCurrentAssignedTm(value)
              }}
              value={currentAssignedTm}
            />
          </Grid.Item>
          <Grid.Container direction="row-reverse" className="hc-pr-md hc-pb-md">
            <Grid.Item>
              <Button
                type="primary"
                onClick={() => {
                  handleTMAssignment()
                  handleCloseAssignModal()
                  setShowIndexedModal(true)
                }}
              >
                Save
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                type="secondary"
                onClick={() => {
                  handleCloseAssignModal()
                }}
              >
                Cancel
              </Button>
            </Grid.Item>
          </Grid.Container>
        </Grid.Container>
      </Modal>
      {!isIndexedOrRejected && (
        <Button
          type="secondary"
          onClick={() => {
            setIsAssignModalVisible(true)
          }}
        >
          Assign &nbsp;
          <EnterpriseIcon icon={PersonIcon} size="inline" />
        </Button>
      )}
    </Grid.Item>
  )
}
