import axios from 'axios'
import { EnvConfig } from '../../configs/apiConfig'
import { useEnv } from '@praxis/component-runtime-env'

export const useInvoiceAggregation = () => {
  const env = useEnv() as EnvConfig

  const getAggregateByStatus = async ({ queryKey }: any) => {
    const [vendors] = queryKey

    let vendorFilter: any[] = []
    if (vendors?.length > 0) {
      vendorFilter = [
        {
          type: 'LongFilter',
          field: 'vendorNumber',
          values: vendors.map((vendor: any) => vendor.vendorId),
        },
      ]
    }
    const fixedFields = {
      aggregateBy: [],
      groupingType: {
        type: 'Pivot',
        field: 'status',
      },
      aggregationFields: [
        { field: 'count', aggregationType: 'COUNT' },
        { field: 'invoiceAmount', aggregationType: 'SUM' },
      ],
      columnTotals: true,
      filters: vendorFilter,
    }
    return await axios.post(
      `${env.baseApiUrl}/invoice_aggregations/aggregate`,
      fixedFields
    )
  }
  return { getAggregateByStatus }
}
