import { Input } from '@enterprise-ui/canvas-ui-react'
import React from 'react'

interface FilterCheckBoxProps {
  id: string
  label: string
  value: string
  onChange: any
  checked: boolean
}

export const FilterCheckBox: React.FC<FilterCheckBoxProps> = ({
  id,
  label,
  onChange,
  value,
  checked,
}) => {
  return (
    <Input.Checkbox
      id={id}
      label={<div className="hc-ws-no-wrap">{label}</div>}
      onChange={onChange}
      value={value}
      checked={checked}
    />
  )
}
