import {
  Avatar,
  Button,
  Dropdown,
  Grid,
  Heading,
  Layout,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { MenuIcon } from '@enterprise-ui/icons'
import { useAuth } from '@praxis/component-auth'
import { useNavigate } from 'react-router'
import { useUserProfile } from '../context/UserProfile/UserProfileProvider'
import { handleLogout } from '../services/auth.service'

const getInitials = (userInfo: any) =>
  `${userInfo.firstName.charAt(0)}${userInfo.lastName.charAt(0)}`

interface HeaderProp {
  onSideNavToggle: any
}
export const Header: React.FC<HeaderProp> = ({ onSideNavToggle }) => {
  const auth = useAuth()
  const [userProfile] = useUserProfile()
  const navigate = useNavigate()
  const { session } = auth

  return (
    <>
      <Layout.MenuButton>
        <Button
          className="C-MenuButton"
          onClick={onSideNavToggle}
          iconOnly
          data-testid="headerMenuButton"
          aria-label="toggle side nav menu"
        >
          <EnterpriseIcon icon={MenuIcon} />
        </Button>
      </Layout.MenuButton>
      <Layout.Header includeRail>
        <Grid.Container justify="space-between" spacing="none">
          <Grid.Item>
            <Grid.Container spacing="none">
              <Grid.Item>
                <Button
                  style={{ backgroundColor: 'transparent', border: 'none' }}
                  size="expanded"
                >
                  <Heading size={3} className="hc-clr-contrast-weak">
                    I N V O I C I O
                  </Heading>
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  onClick={() => {
                    navigate(`/invoices`)
                  }}
                  style={{ backgroundColor: 'transparent', border: 'none' }}
                  size="expanded"
                >
                  <Heading size={5} className="hc-clr-contrast-weak">
                    Home
                  </Heading>
                </Button>
              </Grid.Item>
              {userProfile.isAdmin && (
                <Grid.Item>
                  <Button
                    onClick={() => {
                      navigate(`/admin`)
                    }}
                    style={{ backgroundColor: 'transparent', border: 'none' }}
                    size="expanded"
                  >
                    <Heading size={5} className="hc-clr-contrast-weak">
                      Admin
                    </Heading>
                  </Button>
                </Grid.Item>
              )}
            </Grid.Container>
          </Grid.Item>
          <Grid.Item>
            <span className="hc-fs-md">
              {session?.userInfo?.fullName}, {session?.userInfo?.company}
            </span>
            &nbsp; &nbsp;
            <Dropdown size="dense" location="bottom-right">
              <Avatar
                aria-label={`Avatar showing the initials for ${session?.userInfo?.fullName}`}
                style={{ cursor: 'pointer' }}
              >
                {getInitials(session?.userInfo)}
              </Avatar>
              <Dropdown.Menu>
                <Dropdown.MenuItem
                  data-testid="headerLogoutButton"
                  onClick={() => handleLogout(auth)}
                >
                  Log Out
                </Dropdown.MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Item>
        </Grid.Container>
      </Layout.Header>
    </>
  )
}
