import React, { useReducer, useEffect, useContext } from 'react'
import {
  DraftStateContext,
  DraftDispatchContext,
  DraftState,
  DraftAction,
} from './BulkContext'
import { useDraft } from '../../api/hooks/useDraft'
import { useQueryClient } from '@tanstack/react-query'
import { Layout, Spinner } from '@enterprise-ui/canvas-ui-react'
import {
  formatDraftInvoice,
  formatInvoiceForm,
  transformErrors,
  transformServerErrors,
} from './utils'
import { ValidationError } from 'yup'
import { bulkInvoiceSchema } from './BulkSchema'
import { draftReducer } from './BulkReducer'

const initialState: DraftState = {
  draft: undefined,
  draftId: undefined,
  loading: false,
  draftInvoices: [],
  attachments: [],
  errors: [],
}

type Props = {
  initialState?: DraftState
  children?: React.ReactElement
}

const DraftProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(draftReducer, initialState)
  const { getDraftIdForUser, createInitialDraft } = useDraft()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (state.draft == null) {
      dispatch({ type: 'LOADING', payload: true })
      queryClient
        .fetchQuery(['getDraftById'], getDraftIdForUser)
        .then((res) => {
          if (res.length > 0) {
            dispatch({ type: 'SET_INITIAL_DRAFT', payload: res[0] })
            dispatch({
              type: 'SET_DRAFT_INVOICES',
              payload: { draftInvoices: formatDraftInvoice(res[0]) },
            })
            dispatch({ type: 'LOADING', payload: false })
          } else {
            queryClient
              .fetchQuery(['createDraft'], createInitialDraft)
              .then((res) => {
                dispatch({ type: 'SET_INITIAL_DRAFT', payload: res })
                dispatch({ type: 'LOADING', payload: false })
              })
          }
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.draft])

  useEffect(() => {
    const validateAllDraftInvoices = async () => {
      const errorsArray = []
      const invoiceList = state.draftInvoices.map((draftInvoices) => ({
        invoiceNumber: draftInvoices.invoice.invoiceNumber,
        vendorNumber: draftInvoices.invoice.vendorNumber,
      }))

      for (const [index, invoice] of state.draftInvoices.entries()) {
        let invoiceErrors: any = {}

        // server side validations
        state.draft?.draftInvoices?.forEach((draftInvoice, i) => {
          if (index === i) {
            invoiceErrors = {
              ...invoiceErrors,
              ...transformServerErrors(draftInvoice.errors),
            }
          }
        })

        // client field validations
        try {
          await bulkInvoiceSchema.validate(formatInvoiceForm(invoice.invoice), {
            abortEarly: false,
            context: { invoices: invoiceList, index },
          })
        } catch (err) {
          if (err instanceof ValidationError) {
            const transformedErrors = transformErrors(err, index)
            invoiceErrors = { ...invoiceErrors, ...transformedErrors.errors }
          } else {
            console.error('An unexpected error occurred:', err)
          }
        }

        // attchment validations: Check if there is an attachment for the current invoice
        const attachment = state.attachments.find((att) => att.index === index)
        if (!attachment) {
          invoiceErrors['attachments'] = '*Attachment is required'
        }

        if (Object.keys(invoiceErrors).length > 0) {
          errorsArray.push({ index, errors: invoiceErrors })
        }
      }

      // After all invoices have been validated, dispatch the structured errors
      dispatch({ type: 'SET_ERRORS', payload: errorsArray })
    }

    // Call the validate function if there are draftInvoices
    if (state.draftInvoices && state.draftInvoices.length > 0) {
      validateAllDraftInvoices()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.draft])

  if (state.loading === true) {
    ;<Layout.Body>
      <Spinner />
    </Layout.Body>
  }

  return (
    <DraftStateContext.Provider value={state}>
      <DraftDispatchContext.Provider value={dispatch}>
        {children}
      </DraftDispatchContext.Provider>
    </DraftStateContext.Provider>
  )
}

export default DraftProvider

export function useDrafts(): [DraftState, React.Dispatch<DraftAction>] {
  const state = useContext(DraftStateContext)
  const dispatch = useContext(DraftDispatchContext)

  return [state, dispatch]
}
