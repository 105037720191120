import {
  Button,
  Card,
  Grid,
  Layout,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../configs/apiConfig'
import { handleLogout } from '../services/auth.service'

interface UnauthorizedProps {
  isVendor?: boolean
}

export const Unauthorized: React.FC<UnauthorizedProps> = ({ isVendor }) => {
  const auth = useAuth()
  const env = useEnv() as EnvConfig

  return (
    <Layout data-testid="loginLayout" fullWidth darkMode="false">
      <Layout.Body includeRail>
        <Grid.Container
          className="praxcss-height100vh"
          align="center"
          justify="center"
        >
          <Grid.Item xs={10} md={6} lg={4}>
            <Card className="hc-pa-normal">
              <Heading className="hc-ta-center hc-pb-normal">INVOICIO</Heading>
              {isVendor ? (
                <p>You are not authorized to access the Application.</p>
              ) : (
                <>
                  {' '}
                  <p>
                    You are not authorized to access the Application. Please
                    Raise myaccess request to one of the below AD groups.
                  </p>
                  <span>
                    <strong>User Role:</strong> {env.auth.tmRole?.[0]}
                  </span>
                  <p>
                    <strong>Admin Role:</strong> {env.auth.adminRole?.[0]}
                  </p>
                </>
              )}

              <Button
                data-testid="logoutButton"
                type="primary"
                size="expanded"
                fullWidth
                onClick={() => handleLogout(auth)}
              >
                Log Out
              </Button>
            </Card>
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
    </Layout>
  )
}

export default Unauthorized
