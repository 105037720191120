import { useState, useEffect } from 'react'
import {
  Layout,
  Grid,
  Input,
  Divider,
  Button,
  Card,
  Breadcrumbs,
  Chip,
} from '@enterprise-ui/canvas-ui-react'
import { useParams, useNavigate } from 'react-router'
import { useInvoice } from '../../api/hooks/useInvoice'
import { StatusUpdate } from './StatusUpdate'
import { InvoiceInfoSection } from './InvoiceInfoSection'
import { AttachmentsGallery } from './AttachmentsGallery'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { useUserProfile } from '../../context/UserProfile/UserProfileProvider'
import { AssignTM } from './AssignTM'

export const InvoiceDetail = () => {
  const makeToast = ToastProvider.useToaster()
  const params = useParams()
  const navigate = useNavigate()
  const { getInvoice } = useInvoice()
  const [userProfile] = useUserProfile()
  const [invcData, setInvcData] = useState<any>()
  const [footerState, setFooterState] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [showIndexedModal, setShowIndexedModal] = useState<boolean>(false)
  const [isIndexedOrRejected, setIsIndexedOrRejected] = useState(false) // to disable assign button

  useEffect(() => {
    setLoading(true)
    getInvoice(params?.invoiceId)
      .then((data) => {
        if (data.status === 'Indexed' || data.status === 'Rejected') {
          setIsIndexedOrRejected(true)
        }
        setLoading(false)
        setInvcData(data)
        setFooterState(data?.status === 'New' ? 'accept_reject' : 'workflow')
      })
      .catch((error) => {
        setLoading(false)
        makeToast({
          autoHideDuration: 5000,
          type: 'error',
          heading: 'Error while fetching invoice information',
          message: 'Make sure you are connected to network or try again later',
        })
        setTimeout(() => {
          navigate(`/invoices`)
        }, 3000)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const AcceptRejectSection = () => {
    return (
      <Card>
        <Grid.Container className="hc-pa-md hc-ph-lg" align="center">
          <Grid.Item xs>
            <Input.Label className="hc-fs-sm">
              Do you want to proceed with this invoice?
            </Input.Label>
          </Grid.Item>
          <Grid.Item>
            <Button
              type="primary"
              onClick={() => {
                setFooterState('workflow')
              }}
            >
              Accept
            </Button>
          </Grid.Item>
          <Grid.Item>
            <StatusUpdate
              status={'Rejected'}
              invcData={invcData}
              setInvcData={setInvcData}
              setIsIndexedOrRejected={setIsIndexedOrRejected}
            />
          </Grid.Item>
        </Grid.Container>
      </Card>
    )
  }

  const WorkflowStatusSection = () => {
    return (
      <Card>
        <Grid.Container className="hc-pa-md" align="center" justify="center">
          <Grid.Item></Grid.Item>
          {!['Indexed', 'Rejected'].includes(invcData?.status) && (
            <Grid.Item>
              <StatusUpdate
                status={'Indexed'}
                invcData={invcData}
                setInvcData={setInvcData}
                setIsIndexedOrRejected={setIsIndexedOrRejected}
              />
            </Grid.Item>
          )}
          {!['Rejected'].includes(invcData?.status) && (
            <Grid.Item>
              <StatusUpdate
                status={'Rejected'}
                invcData={invcData}
                setInvcData={setInvcData}
                setIsIndexedOrRejected={setIsIndexedOrRejected}
              />
            </Grid.Item>
          )}
        </Grid.Container>
      </Card>
    )
  }

  const Footer = (props: any) => {
    return (
      <div className="footer">
        {props.state === 'accept_reject' && <AcceptRejectSection />}
        {props.state === 'workflow' && <WorkflowStatusSection />}
      </div>
    )
  }

  const InvoiceHeader = () => {
    return (
      <Breadcrumbs className="hc-mh-none">
        <Breadcrumbs.Item
          onClick={() => {
            navigate('/invoices')
          }}
        >
          <span className="hc-fs-sm hc-clr-contrast">Home</span>
        </Breadcrumbs.Item>
        <Breadcrumbs.Item href="https://www.target.com">
          <Input.Label className="hc-fs-md hc-clr-grey02">
            Reqest ID&nbsp;
            <strong style={{ color: '#2951a3' }}>
              {invcData?.invoiceId ?? 'NA'}
            </strong>
          </Input.Label>
        </Breadcrumbs.Item>
      </Breadcrumbs>
    )
  }

  return (
    <Layout.Body data-testid="homePage" includeRail>
      <div className="viewScreenSplit viewScreenAttachment">
        <AttachmentsGallery invcId={params?.invoiceId} />
      </div>

      {!loading && invcData && (
        <div className="viewScreenSplit viewScreenInfo">
          <Grid.Item xs className="hc-pa-xl">
            <Grid.Container>
              <Grid.Item>
                <InvoiceHeader />
              </Grid.Item>
              <Grid.Item xs className="hc-pt-sm">
                <Chip size="dense">{invcData?.status}</Chip>
              </Grid.Item>
              {!userProfile?.isVendor && (
                <Grid.Item>
                  <AssignTM
                    invcData={invcData}
                    setInvcData={setInvcData}
                    setShowIndexedModal={setShowIndexedModal}
                    isIndexedOrRejected={isIndexedOrRejected}
                  />
                </Grid.Item>
              )}
            </Grid.Container>

            <Divider className="hc-pv-xs" />
            <InvoiceInfoSection
              invoiceData={invcData}
              setInvcData={setInvcData}
            />
          </Grid.Item>
        </div>
      )}

      {!userProfile?.isVendor &&
        showIndexedModal &&
        !['Indexed', 'Rejected'].includes(invcData?.status) && (
          <Footer state={footerState} />
        )}
    </Layout.Body>
  )
}
