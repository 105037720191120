//TO keep all data models here - exclduing api data models
export interface TeamMemberDetails {
  zid: string
  name: string
}

export enum ActionEvent {
  UPDATE = 'Update',
  CREATE = 'Create',
}
