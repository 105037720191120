import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { SubmitInvoiceForm } from './SubmitInvoiceForm'
import { Formik, FormikProps } from 'formik'
import { useInvoice } from '../../api/hooks/useInvoice'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAttachment } from '../../api/hooks/useAttachments'
import {
  Button,
  Grid,
  GridContainer,
  GridItem,
  Heading,
  Spinner,
  Timeline,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { formatAttachmentMetadata, formatCreateInvoice } from './utils'
import { object, number, string, array, InferType, boolean } from 'yup'
import { Status } from '../../constants/status'
import { InvoiceResponse } from '../../api/models/InvoiceResponse'

export interface InvoiceForm extends InferType<typeof schema> {}
export function getTimelineType(
  status: 'pending' | 'uploading' | 'completed' | 'error' | 'cancelled'
): 'success' | 'error' | 'default' {
  switch (status) {
    case 'completed':
      return 'success'
    case 'error':
      return 'error'
    default:
      return 'default'
  }
}

const initialInvoiceValues: InvoiceForm = {
  invoiceNumber: '',
  vendorNumber: 0,
  vendorName: '',
  vendorContactName: '',
  vendorEmailAddress: '',
  vendorType: '',
  purchaseOrderNumber: 0,
  locationId: '',
  departmentId: 0,
  invoiceDate: '',
  productCost: 0,
  invoiceAmount: 0,
  allowanceAmount: 0,
  attachments: [],
  isPOValid: false,
  allowanceCosts: undefined,
}

const schema = object({
  invoiceNumber: string()
    .required('Invoice Number is Mandatory')
    .min(4, 'Invoice Length Should be minimum 4 Chars')
    .max(20, 'Invoice Length Should be maximum 20 Chars'),
  vendorNumber: number()
    .required('Please Select a Valid Vendor number')
    .positive('Please Select a Valid Vendor number'),
  vendorName: string().required(),
  vendorContactName: string().required(),
  vendorEmailAddress: string().required().email('Not a valid Email'),
  vendorType: string().optional(),
  purchaseOrderNumber: number()
    .required('Purchase Order is Mandatory')
    .test('valid-purchase-order', 'Invalid Purchase Order', function (value) {
      const isWithinRange = value >= 1 && value <= 9999999
      const hasElevenDigits = value.toString().length === 11
      const startsWith1000 = value.toString().startsWith('1000')

      return isWithinRange || (hasElevenDigits && startsWith1000)
    }),
  locationId: string()
    .required('Location ID is mandatory')
    .min(1, 'Invalid Location Id')
    .max(4, 'Invaild Location Id'),
  departmentId: number()
    .required('Department is Mandatory')
    .moreThan(1, 'Invalid Department')
    .lessThan(999, 'Invaild Department'),
  invoiceDate: string()
    .required()
    .test(
      'valid-invoice-date',
      'Inovice Date should be always within 18 months',
      function (value) {
        if (!value) return false
        const invoiceDate = new Date(value)
        const currentDate = new Date()

        const eighteenMonthsAgo = new Date()
        eighteenMonthsAgo.setMonth(currentDate.getMonth() - 18)
        if (invoiceDate.getTime() < eighteenMonthsAgo.getTime()) {
          return false
        }

        return true
      }
    ),
  productCost: number()
    .required()
    .required()
    .moreThan(0, 'Number should be greater then O'),
  allowanceCosts: array()
    .optional()
    .of(
      object({
        allowanceType: string().required('Allowance Type is required'),
        allowanceCode: string().required('Allowance Code is required'),
        allowanceAmount: number()
          .required('Allowance Amount is required')
          .moreThan(0, 'Number should be greater then 0'),
      })
    )
    .max(5, 'Maximum 5 AOC allocawd'),
  invoiceAmount: number()
    .required()
    .required()
    .moreThan(0, 'Number should be greater then O'),
  allowanceAmount: number().required(),
  attachments: array().required().min(1, 'Minimum one attachment Required'),
  isPOValid: boolean().defined(),
})

export const SubmitInvoiceWrapper = ({ handleDrawerClose }: any) => {
  const navigate = useNavigate()
  const makeToast = useToaster()
  const {
    upload,
    addAttachments,
    getAttachmentsByParentId,
    removeAttachments,
    retryOnce,
  } = useAttachment()
  const { postInvoice, getInvoicesForAppliedFilters } = useInvoice()
  const { isLoading, data, mutateAsync } = useMutation({
    mutationKey: ['postInvoice'],
    mutationFn: postInvoice,
  })
  const client = useQueryClient()

  const attachments = useMemo(
    () => getAttachmentsByParentId(data?.invoiceId.toString()),
    [data, getAttachmentsByParentId]
  )

  const [formState, setFormState] = useState<{
    invoiceId?: string
    attachmentUploadView: boolean
    attachmentStatus: boolean
  }>({ attachmentUploadView: false, attachmentStatus: false })

  useEffect(() => {
    if (
      attachments.length > 0 &&
      attachments.every((attachment) => attachment?.status === 'completed')
    ) {
      setFormState((state) => ({ ...state, attachmentStatus: false }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments])

  async function checkDulicates(values: InvoiceForm): Promise<boolean> {
    // in app duplicate
    const searchResponse = await client.fetchQuery({
      queryKey: ['getInvoice'],
      queryFn: () =>
        getInvoicesForAppliedFilters({
          vendorNumber: values.vendorNumber,
          invoiceNumber: values.invoiceNumber,
          page: 1,
          perPage: 100,
        }),
    })

    if (searchResponse?.totalCount > 0) {
      // check if any not rejected
      const nonRejectedInvoices = searchResponse?.invoiceResponseList.filter(
        (invoice: InvoiceResponse) => invoice.status !== Status.REJECTED
      )
      if (nonRejectedInvoices.length > 0) {
        const invoiceId = searchResponse?.invoiceResponseList[0].invoiceId
        makeToast({
          message: `Invoice with Vendor:${values.vendorNumber} and Invoice Number:${values.invoiceNumber} is already open`,
          heading: 'Duplicate Invoice Submission',
          type: 'alert',
          autoHideDuration: 100000,
          links: [
            {
              text: 'Click here to View Invoice',
              onClick: () => {
                navigate(`/view/${invoiceId}`)
              },
            },
          ],
        })

        return true
      }
      return false
    }

    // tap duplicate

    return false
  }

  const handleSubmitInvoice = async (
    submitType: 'submit' | 'submit-new',
    formikProps: FormikProps<InvoiceForm>
  ) => {
    const { values, submitForm, setSubmitting, isValid, resetForm } =
      formikProps

    await submitForm()

    if (!isValid) return

    const duplicate = await checkDulicates(values)

    if (!duplicate) {
      try {
        const invoice = await mutateAsync(formatCreateInvoice(values))
        makeToast({
          type: 'success',
          message: 'Invoice submitted successfully!',
          heading: 'Success',
          autoHideDuration: 5000,
        })
        if (submitType === 'submit-new') {
          let attachments = values.attachments.map((attachment) => ({
            name: attachment.name,
            parentId: invoice.invoiceId.toString(),
            file: attachment.file,
            attachmentMetaData: formatAttachmentMetadata(invoice),
            asyncUpload: true,
          }))
          addAttachments(attachments)
          resetForm()
        } else {
          let attachments = values.attachments.map((attachment) => ({
            name: attachment.name,
            parentId: invoice.invoiceId.toString(),
            file: attachment.file,
            attachmentMetaData: formatAttachmentMetadata(invoice),
            asyncUpload: false,
          }))
          upload(attachments)
          setFormState((state) => ({
            ...state,
            invoiceId: invoice.invoiceId.toString(),
            attachmentUploadView: true,
          }))
        }
      } catch (error: any) {
        let message = error?.response?.data?.message
        makeToast({
          type: 'error',
          message,
          heading: 'Invoice Submisson Failed',
        })
      } finally {
        setSubmitting(false)
      }
    }
    setSubmitting(false)
  }

  return (
    <>
      <Formik
        initialValues={initialInvoiceValues}
        validationSchema={schema}
        validateOnBlur={true}
        onSubmit={() => {}}
        initialTouched={true}
      >
        {(props) => (
          <div>
            {!formState.attachmentUploadView && (
              <SubmitInvoiceForm
                handleDrawerClose={handleDrawerClose}
                handleSubmitInvoice={handleSubmitInvoice}
              />
            )}
            {formState.attachmentUploadView && data && (
              <GridContainer className={'hc-pa-md'} align="center">
                <GridItem xs={12}>
                  <Heading size={5}> Invoice Submission Status</Heading>
                </GridItem>
                <GridItem xs={12}>
                  <Timeline>
                    <Timeline.Item type={isLoading ? 'default' : 'success'}>
                      {isLoading && <p>Submitting the Invoice</p>}
                      {!isLoading && (
                        <p>Submitted the Invoice with ID: {data.invoiceId}</p>
                      )}
                    </Timeline.Item>

                    <Timeline.Flag>Uploading Attachments</Timeline.Flag>
                    {attachments.map((attachment) => (
                      <Timeline.Item type={getTimelineType(attachment?.status)}>
                        {attachment?.status === 'uploading' && (
                          <>
                            <Spinner size="dense" /> &nbsp; Uploading{' '}
                            {attachment.name}
                          </>
                        )}
                        {attachment?.status !== 'uploading' && (
                          <p>
                            {attachment.name} &nbsp;
                            {attachment?.status === 'error' && (
                              <span
                                className="hc-clr-error"
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  retryOnce(
                                    attachment.name,
                                    attachment.parentId
                                  )
                                }}
                              >
                                retry
                              </span>
                            )}
                          </p>
                        )}
                      </Timeline.Item>
                    ))}
                  </Timeline>
                </GridItem>
                <GridItem xs={12}>
                  <Grid.Container>
                    <Grid.Item xs={12}>
                      <Heading size={5}>
                        Do you want to submit another request?
                      </Heading>
                    </Grid.Item>
                    <Grid.Item>
                      <Button
                        type="secondary"
                        onClick={() => {
                          props.resetForm()
                          setFormState((state) => ({
                            ...state,
                            attachmentUploadView: false,
                          }))
                          removeAttachments(formState.invoiceId ?? '')
                        }}
                      >
                        Yes
                      </Button>
                    </Grid.Item>
                    <Grid.Item>
                      <Button
                        type="primary"
                        onClick={() => {
                          props.resetForm()
                          setFormState((state) => ({
                            ...state,
                            attachmentUploadView: false,
                          }))
                          removeAttachments(formState.invoiceId ?? '')
                          handleDrawerClose(false)
                        }}
                      >
                        No Thanks
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                </GridItem>
                <GridItem></GridItem>
              </GridContainer>
            )}
          </div>
        )}
      </Formik>
    </>
  )
}
