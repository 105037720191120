import { TeamMemberDetails } from '../../models/TeamMemberDetails'
import axios from 'axios'
import { EnvConfig } from '../../../configs/apiConfig'
import { useEnv } from '@praxis/component-runtime-env'
import _ from 'lodash'

export const useTeamMember = () => {
  const env = useEnv() as EnvConfig

  const getTmsByADgroup = async (): Promise<TeamMemberDetails[]> => {
    const allGroups = [...env.auth.tmRole, ...env.auth.adminRole]
    const promises = allGroups.map(async (group) => {
      const res = await axios.get(
        `${env.baseApiUrl}/team_members?active_directory_group=${group}&key=${env.apiKey}`
      )
      return res.data
    })

    const results = await Promise.all(promises)
    const consolidatedList = ([] as TeamMemberDetails[]).concat(...results)

    return _.uniqBy(consolidatedList, 'zid')
  }
  return { getTmsByADgroup }
}
