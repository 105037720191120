import { DraftAction, DraftState } from './BulkContext'

export const draftReducer = (state: DraftState, action: DraftAction) => {
  switch (action.type) {
    case 'SET_INITIAL_DRAFT':
      return {
        ...state,
        draft: action.payload,
        draftId: action.payload.draftId,
        // draftInvoices: formatDraftInvoice(action.payload),
      }
    case 'LOADING':
      return { ...state, loading: action.payload }
    case 'UPDATE_DRAFT':
      return { ...state, draft: action.payload }
    case 'SET_DRAFT_INVOICE': {
      const updatedDraftInvoices = [...state.draftInvoices]
      updatedDraftInvoices[action.payload.index] = action.payload.draftInvoice
      return {
        ...state,
        draftInvoices: updatedDraftInvoices,
      }
    }
    case 'SET_DRAFT_INVOICES': {
      return { ...state, draftInvoices: [...action.payload.draftInvoices] }
    }
    case 'ADD_ATTACHMENT': {
      const attachmentIndex = state.attachments.findIndex(
        (att) => att.index === action.payload.index
      )

      // If found, replace the existing attachment, otherwise add the new one
      const newAttachments =
        attachmentIndex !== -1
          ? state.attachments.map((att, idx) =>
              idx === attachmentIndex
                ? { index: action.payload.index, file: action.payload.file }
                : att
            )
          : [
              ...state.attachments,
              { index: action.payload.index, file: action.payload.file },
            ]

      return {
        ...state,
        attachments: newAttachments,
      }
    }
    case 'REMOVE_ATTACHMENT': {
      // Remove the attachment with the given index
      const newAttachments = state.attachments.filter(
        (att) => att.index !== action.payload.index
      )

      return {
        ...state,
        attachments: newAttachments,
      }
    }
    case 'SET_ERRORS': {
      return { ...state, errors: [...action.payload] }
    }

    case 'CLEAR': {
      return { ...state, draftInvoices: [], attachments: [], errors: [] }
    }
    default:
      return { ...state }
  }
}
