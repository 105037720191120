import { useUserProfile } from '../UserProfile/UserProfileProvider'
import {
  DashboardAction,
  DashboardDispatchContext,
  DashboardState,
  DashboardStateContext,
} from './DashboardContext'
import { useReducer, useEffect, useContext, Dispatch } from 'react'
import { Role } from '../UserProfile/UserProfileContext'
import { DashboardCard } from '../../constants/DashboardCards'
import { initialValue } from '../../constants/SearchFilterInitialValues'
import * as qs from 'qs'
import { useLocation } from 'react-router-dom'
import { useTeamMember } from '../../api/hooks/core/useTeamMember'
import { reducer } from './DashboardReducer'
import { useInvoiceAggregation } from '../../api/hooks/useInvoiceAggregation'
import { useQueryClient } from '@tanstack/react-query'

type Props = {
  initialState?: DashboardState
  children?: React.ReactElement
}

export const DashboardProvider: React.FC<Props> = ({
  initialState = {},
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [userProfile] = useUserProfile()
  const location = useLocation()
  const { getTmsByADgroup } = useTeamMember()
  const { getAggregateByStatus } = useInvoiceAggregation()
  const client = useQueryClient()

  const formatDashbordData = (data: any) => {
    const mapping = [
      { key: 'total', text: 'All' },
      { key: 'New', text: 'In Progress' },
      { key: 'Indexed', text: 'Indexed' },
      { key: 'Rejected', text: 'Rejected' },
    ]

    return mapping.map(
      ({ key, text }) =>
        ({
          status: key,
          text: text,
          count: data?.[key]?.['count'] ?? 0,
          amount: data?.[key]?.['invoiceAmount'] ?? 0,
        } as DashboardCard)
    )
  }
  useEffect(() => {
    if (userProfile.userRole === Role.VENDOR && userProfile.vendorList) {
      client
        .fetchQuery({
          queryKey: [userProfile.vendorList],
          queryFn: getAggregateByStatus,
        })
        .then((res) => {
          const data = res.data[0]
          dispatch({ type: 'SET_CARD_DATA', cards: formatDashbordData(data) })
        })
    }

    if (
      userProfile.userRole === Role.ADMIN ||
      userProfile.userRole === Role.USER
    ) {
      client
        .fetchQuery({
          queryKey: [],
          queryFn: getAggregateByStatus,
        })
        .then((res) => {
          const data = res.data[0]
          dispatch({ type: 'SET_CARD_DATA', cards: formatDashbordData(data) })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile])

  useEffect(() => {
    dispatch({ type: 'SET_FILTERS', filters: initialValue })
    dispatch({
      type: 'UPDATE_FILTERS',
      activeFilters: qs.parse(location.search.replace('?', ''), {
        arrayLimit: 100,
      }),
    })
  }, [location.search])

  useEffect(() => {
    if (!userProfile?.isVendor) {
      getTmsByADgroup().then((response: any) => {
        setDefaultFilters(dispatch, response, 'assignedUserId')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DashboardStateContext.Provider value={state}>
      <DashboardDispatchContext.Provider value={dispatch}>
        {children}
      </DashboardDispatchContext.Provider>
    </DashboardStateContext.Provider>
  )
}

function useDashboard(): [DashboardState, React.Dispatch<DashboardAction>] {
  const stateContext = useContext(DashboardStateContext)
  const dispatch = useContext(DashboardDispatchContext)
  return [stateContext as DashboardState, dispatch]
}

export { useDashboard }

function setDefaultFilters(dispatch: Dispatch<any>, data: any, field: string) {
  const enrichedData = data.map((val: any) => {
    return {
      id: val.zid,
      label: val.name,
      value: val.zid,
      isChecked: false,
    }
  })
  let obj: any = {}
  obj[field] = enrichedData
  dispatch({
    type: 'SET_DEFAULT_SIDE_FILTERS_VALUES',
    values: obj,
  })
}
