import { EnvConfig } from '../../configs/apiConfig'
import { useEnv } from '@praxis/component-runtime-env'
import axios from 'axios'
import { useAuth } from '@praxis/component-auth'
import { Draft, UpdateDraft } from '../models/Draft'
import { VendorInfo } from '../../views/Bulk/VendorSelectionSection'

export const useDraft = () => {
  const env = useEnv() as EnvConfig
  const { session } = useAuth()

  const getDraftIdForUser = async (): Promise<Draft[]> => {
    let userId = session?.userInfo?.lanId
    const res = await axios.get(
      `${env.baseApiUrl}/invoices/draft?assignedUserId=${userId}&status=ACTIVE`
    )
    return res.data
  }

  const createInitialDraft = async (): Promise<Draft> => {
    let userId = session?.userInfo?.lanId
    const draftObj = {
      assignedUserId: userId,
    }
    const res = await axios.post(`${env.baseApiUrl}/invoices/draft/`, draftObj)
    return res.data
  }

  const getDraftInvoicesByDraftId = async (draftId: number): Promise<Draft> => {
    const res = await axios.get(`${env.baseApiUrl}/invoices/draft/${draftId}`)
    return res.data
  }

  const updateDraft = async (updateDraftRequest: {
    draftId: number
    updateDraftObj: UpdateDraft
  }): Promise<Draft> => {
    const res = await axios.put(
      `${env.baseApiUrl}/invoices/draft/${updateDraftRequest.draftId}`,
      updateDraftRequest.updateDraftObj
    )
    return res.data
  }

  const deleteDraftInvoicesByDraftId = async (
    draftId: number
  ): Promise<Draft> => {
    const res = await axios.delete(
      `${env.baseApiUrl}/invoices/draft/${draftId}`
    )
    return res.data
  }

  const submitDraft = async (draftId: number): Promise<Draft> => {
    const res = await axios.post(
      `${env.baseApiUrl}/invoices/draft/${draftId}/submit`
    )
    return res.data
  }

  const downloadTemplate = async (vendorInfo: VendorInfo): Promise<any> => {
    const params = {
      vendorNumber: vendorInfo.vendorNumber,
      vendorName: vendorInfo.vendorName,
      vendorEmail: vendorInfo.vendorEmailAddress,
    }
    const res = await axios.get(
      `${env.baseApiUrl}/invoices/draft/download_template/`,
      {
        params: params,
        responseType: 'blob',
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/octet-stream',
        },
      }
    )
    return res.data
  }

  const uploadTemplate = async (upload: {
    draftId: number
    file: any
  }): Promise<string> => {
    const formData = new FormData()
    formData.append('file', upload.file)

    const res = await axios.post(
      `${env.baseApiUrl}/invoices/draft/${upload.draftId}/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'text/plain',
        },
      }
    )
    return res.data
  }

  return {
    getDraftIdForUser,
    createInitialDraft,
    getDraftInvoicesByDraftId,
    updateDraft,
    deleteDraftInvoicesByDraftId,
    submitDraft,
    downloadTemplate,
    uploadTemplate,
  }
}
