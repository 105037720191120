import {
  Button,
  Divider,
  Form,
  Grid,
  Heading,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'
import { useUserProfile } from '../../context/UserProfile/UserProfileProvider'
import { convertForSelectOption } from '../submitInvoice/utils'
import { useAuth } from '@praxis/component-auth'
import { useDraft } from '../../api/hooks/useDraft'
import fileDownload from 'js-file-download'
import { get } from 'lodash'
import { useDrafts } from './BulkProvider'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { formatDraftInvoice } from './utils'

export interface VendorInfo {
  vendorNumber?: number
  vendorEmailAddress?: string
  vendorContactName?: string
  vendorName?: string
}

export const VendorSelectionSection = () => {
  const [vendorInfo, setVendorInfo] = useState<VendorInfo>({})
  const { session } = useAuth()
  const queryClient = useQueryClient()

  const [userProfile] = useUserProfile()
  const [state, dispatch] = useDrafts()
  const { getDraftIdForUser, downloadTemplate, uploadTemplate } = useDraft()

  const { mutateAsync } = useMutation(uploadTemplate, {
    retry: 1,
    onError: (error: any, variables) => {},
    onSuccess: (data, variables) => {
      dispatch({ type: 'LOADING', payload: true })

      queryClient
        .fetchQuery(['getDraftByBP'], getDraftIdForUser, { retry: 1 })
        .then((res) => {
          if (res.length > 0) {
            dispatch({ type: 'SET_INITIAL_DRAFT', payload: res[0] })
            dispatch({
              type: 'SET_DRAFT_INVOICES',
              payload: { draftInvoices: formatDraftInvoice(res[0]) },
            })
            dispatch({ type: 'LOADING', payload: false })
          }
        })
        .catch((error: any) => {
          console.log(error)
          dispatch({ type: 'LOADING', payload: false })
        })
    },
  })

  const getVendors = () => {
    return userProfile.vendorList
      ?.map((vendor) => vendor.vendorId)
      .map(convertForSelectOption)
  }

  const handleVendorNumberChange = async (id: string, value: any) => {
    const vendorName = userProfile.vendorList?.find(
      (vendor) => vendor.vendorId === value
    )?.vendorName

    setVendorInfo({
      vendorNumber: value,
      vendorEmailAddress: session?.userInfo?.email!!,
      vendorContactName: session?.userInfo?.fullName!!,
      vendorName: vendorName!!,
    })
  }

  const handleDownloadTemplate = async () => {
    const file = await downloadTemplate(vendorInfo)
    fileDownload(file, 'template.xlsx')
  }

  const handleUploadTemplate = (files?: FileList[]) => {
    if (files?.[0] !== undefined) {
      mutateAsync({ draftId: state.draftId!!, file: files[0] })
    }
  }

  return (
    <Grid.Container
      className="hc-pa-lg"
      style={{ backgroundColor: '#fff' }}
      justify="center"
    >
      <Grid.Item className="hc-pb-sm" xs={12}>
        <Heading
          size={5}
          style={{ fontWeight: 'normal' }}
          className="hc-clr-grey02"
        >
          Please Select Vendor for downloading Bulk Excel template
        </Heading>
      </Grid.Item>
      <Grid.Item xs={8}>
        <Grid.Item xs={12}>
          <Grid.Container align="center">
            <Grid.Item xs={8}>
              <Form.Field
                type="select"
                id={'vendorNumber'}
                label={'Vendor Number'}
                options={getVendors()}
                onUpdate={handleVendorNumberChange}
                value={vendorInfo?.vendorNumber}
                hintText="Please Select the vendor to download the bulk template"
                required
              />
            </Grid.Item>
            <Grid.Item xs={4}>
              <Button
                type="submit"
                disabled={vendorInfo.vendorNumber === undefined}
                onClick={handleDownloadTemplate}
                fullWidth
                size="expanded"
              >
                Download Template
              </Button>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Item>
      <Divider direction="vertical" />
      <Grid.Item xs={4}>
        <Grid.Container align="center" justify="flex-end">
          <Grid.Item xs={12}>
            <Input.DropArea
              className="hc-pa-xs"
              type="drop-area"
              id="attachments"
              instructionText={
                'Plese fill the template with Invoice Details before you Upload'
              }
              dropText="Drag and Drop Invoice Template Here"
              fullwidth
              onUpdate={(e: FormDataEvent) => {
                const files =
                  get(e, 'dataTransfer.files') || get(e, 'target.files')
                handleUploadTemplate(files)
              }}
              required
            ></Input.DropArea>
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
    </Grid.Container>
  )
}
