import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../configs/apiConfig'
import {
  ExemptionVendorResponse,
  NewExemptionVendor,
  UpdateExemptionVendor,
} from '../models/ExemptionVendor'
import axios from 'axios'

export const useExemptionVendor = () => {
  const env = useEnv() as EnvConfig

  const getAllVendors = async (): Promise<[ExemptionVendorResponse]> => {
    const res = await axios.get(`${env.baseApiUrl}/exemption_vendors`)
    return res.data
  }

  const postVendor = (
    newExemptionVendor: NewExemptionVendor
  ): Promise<ExemptionVendorResponse> => {
    return axios
      .post(`${env.baseApiUrl}/exemption_vendors`, newExemptionVendor)
      .then((res) => Promise.resolve(res.data))
  }

  const getVendorDetailsFromId = async (
    id: number
  ): Promise<ExemptionVendorResponse> => {
    const res = await axios.get(`${env.baseApiUrl}/exemption_vendors/${id}`)
    return res.data
  }

  const updateVendor = (
    id: number,
    updateExemptionVendor: UpdateExemptionVendor
  ): Promise<ExemptionVendorResponse> => {
    return axios
      .put(`${env.baseApiUrl}/exemption_vendors/${id}`, updateExemptionVendor)
      .then((res) => Promise.resolve(res.data))
  }

  return {
    getAllVendors,
    postVendor,
    getVendorDetailsFromId,
    updateVendor,
  }
}
