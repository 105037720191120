import {
  Button,
  Caption,
  Divider,
  ExpandableSection,
  Form,
  Grid,
  GridContainer,
  GridItem,
  Heading,
  Spinner,
  Input,
  Card,
} from '@enterprise-ui/canvas-ui-react'
import { useFormikContext } from 'formik'
import { useUserProfile } from '../../context/UserProfile/UserProfileProvider'
import { useAuth } from '@praxis/component-auth'
import { get } from 'lodash'
import { useEffect, useState } from 'react'
import moment from 'moment'
import {
  EnterpriseIcon,
  FileIcon,
  RedoIcon,
  TrashIcon,
} from '@enterprise-ui/icons'
import { InvoiceForm } from './SubmitInvoiceWrapper'
import { usePurchaseOrder } from '../../api/hooks/usePurchaseOrder'
import { useQueryClient } from '@tanstack/react-query'
import { convertForSelectOption, formatPurchaseOrderData } from './utils'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-autocomplete'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { AllowanceCodes } from '../../constants/AllowanceCodes'
import { AllowanceCost } from '../../api/models/InvoiceResponse'
import { getCurrency } from '../../utils/Formatter'

export const SubmitInvoiceForm = ({
  handleDrawerClose,
  handleSubmitInvoice,
}: any) => {
  const { session } = useAuth()
  const [userProfile] = useUserProfile()
  const { getPurchaseOrder } = usePurchaseOrder()
  const client = useQueryClient()
  const [tempAllowance, setTempAllowance] = useState<any>({
    allowanceCode: undefined,
    allowanceAmount: 0,
  })

  const formik = useFormikContext<InvoiceForm>()

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    setFieldValue,
    resetForm,
  } = formik
  const [loading, setLoading] = useState(false)
  const aocCodeOptions = AllowanceCodes.map((item) => ({
    id: item.code,
    value: `${item.code} - ${item.label}`,
    label: `${item.code} - ${item.label}`,
  }))

  const isAddButtonEnabled = () => {
    return (
      tempAllowance.allowanceCode !== undefined &&
      tempAllowance.allowanceAmount > 0
    )
  }

  const handleAddAllowanceCode = () => {
    const header = AllowanceCodes.find(
      (header) => header.code === tempAllowance.allowanceCode.id
    )
    const newAoc = {
      allowanceType: header?.type,
      allowanceCode: tempAllowance.allowanceCode.value,
      allowanceAmount: tempAllowance.allowanceAmount,
    }
    formik.setFieldValue('allowanceCosts', [
      ...(formik.values.allowanceCosts ?? []),
      newAoc,
    ])
    setTempAllowance({ allowanceCode: undefined, allowanceAmount: 0 })
  }

  const handleRemoveAllowanceCode = (index: number) => {
    const newAocCosts = formik.values.allowanceCosts?.slice()
    newAocCosts?.splice(index, 1)
    formik.setFieldValue('allowanceCosts', newAocCosts)
  }
  const getVendors = () => {
    return userProfile.vendorList
      ?.map((vendor) => vendor.vendorId)
      .map(convertForSelectOption)
  }

  const handleVendorNumberChange = async (id: string, value: any) => {
    setFieldValue('vendorNumber', value)
    const vendorName = userProfile.vendorList?.find(
      (vendor) => vendor.vendorId === value
    )?.vendorName
    setFieldValue('vendorEmailAddress', session?.userInfo?.email, true)
    setFieldValue('vendorName', vendorName)
    setFieldValue('vendorContactName', session?.userInfo?.fullName)

    formik.validateForm()
  }

  const calculateInvoiceAmount = (
    productCost: number | '',
    aocCosts?: AllowanceCost[]
  ) => {
    let calculatedInvoiceAmount = productCost === '' ? 0 : productCost

    aocCosts?.forEach((aoc) => {
      if (aoc.allowanceType === 'Allowance') {
        calculatedInvoiceAmount -= parseFloat(aoc.allowanceAmount.toString())
      } else if (aoc.allowanceType === 'Charge') {
        calculatedInvoiceAmount += parseFloat(aoc.allowanceAmount.toString())
      }
    })

    return parseFloat(calculatedInvoiceAmount.toFixed(2))
  }

  useEffect(() => {
    const invoiceAmount = calculateInvoiceAmount(
      values.productCost as number,
      values.allowanceCosts
    )
    setFieldValue('invoiceAmount', invoiceAmount)
  }, [values.productCost, values.allowanceCosts, setFieldValue])

  const handlePurchaseOrderChange = async () => {
    const errors = await formik.validateForm()

    if (errors.purchaseOrderNumber === undefined) {
      try {
        const purchaseOrder = await client.fetchQuery({
          queryKey: ['puerchase_order'],
          queryFn: () => getPurchaseOrder(formik.values.purchaseOrderNumber),
        })

        if (purchaseOrder.length > 0) {
          const [departemetId] = formatPurchaseOrderData(purchaseOrder)
          formik.setFieldValue('isPOValid', true)
          formik.setFieldValue('departmentId', departemetId)
        } else {
          formik.setFieldValue('isPOValid', false)
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      formik.setFieldValue('isPOValid', false)
      formik.setFieldValue('departmentId', 0)
    }
  }

  const handleAttachments = (files: any) => {
    const currentAttachments = [...values.attachments]
    for (let i = 0; i < files.length; i++) {
      let duplicate = values.attachments.some(
        (x: any) => x.file?.name === files?.item(i).name
      )
      if (!duplicate) {
        currentAttachments.push({
          file: files?.item(i),
          uploadDate: moment().format('MM/DD/YYYY'),
          isUploaded: false,
          name: files?.item(i).name,
        })
      }
    }
    setFieldValue('attachments', [...currentAttachments])
  }

  const removeAttachment = (removedFile: any) => {
    const files = values.attachments.filter((file: any) => file !== removedFile)
    setFieldValue('attachments', [...files])
  }

  return (
    <>
      <Form>
        <div
          style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: '90vh',
            display: 'inherit',
          }}
          className="hc-pb-4x"
        >
          <ExpandableSection startExpanded padding="dense">
            <Heading size={6}>Vendor Info</Heading>
            <ExpandableSection.Content>
              <Grid.Container spacing="dense">
                <Grid.Item xs={6}>
                  <Form.Field
                    type="select"
                    id="vendorNumber"
                    label={'Vendor Number'}
                    options={getVendors()}
                    onUpdate={handleVendorNumberChange}
                    value={values.vendorNumber}
                    error={
                      touched.vendorNumber && errors.vendorNumber ? true : false
                    }
                    errorText={errors.vendorNumber}
                    required
                  />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    type="text"
                    id="vendorName"
                    label={'Vendor Name'}
                    value={values.vendorName}
                    disabled
                  ></Form.Field>
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    type="email"
                    id="vendorContactName"
                    label={'Vendor Contact'}
                    value={values.vendorContactName}
                    disabled
                  ></Form.Field>
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    type="email"
                    id="vendorEmailAddress"
                    label={'Vendor Email'}
                    onChange={handleChange}
                    value={values.vendorEmailAddress}
                    error={
                      touched.vendorEmailAddress && errors.vendorEmailAddress
                        ? true
                        : false
                    }
                    errorText={errors.vendorEmailAddress}
                    required
                  ></Form.Field>
                </Grid.Item>
              </Grid.Container>
            </ExpandableSection.Content>
          </ExpandableSection>
          <Divider />
          <ExpandableSection startExpanded padding="dense">
            <Heading size={6}>Document Info</Heading>
            <ExpandableSection.Content>
              <Grid.Container spacing="dense">
                <Grid.Item xs={6}>
                  <Form.Field
                    type="text"
                    id="invoiceNumber"
                    label={'Invoice Number'}
                    onChange={handleChange}
                    value={values.invoiceNumber}
                    error={
                      touched.invoiceNumber && errors.invoiceNumber
                        ? true
                        : false
                    }
                    errorText={errors.invoiceNumber}
                    required
                  ></Form.Field>
                </Grid.Item>

                <Grid.Item xs={6}>
                  <Form.Field
                    type="number"
                    id="purchaseOrderNumber"
                    label={'Purchase Order'}
                    onChange={handleChange}
                    value={values.purchaseOrderNumber}
                    onBlur={handlePurchaseOrderChange}
                    error={
                      touched.purchaseOrderNumber && errors.purchaseOrderNumber
                        ? true
                        : false
                    }
                    errorText={errors.purchaseOrderNumber}
                    required
                  ></Form.Field>
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    type="number"
                    id="locationId"
                    label={'Location'}
                    onChange={handleChange}
                    value={values.locationId}
                    error={
                      touched.locationId && errors.locationId ? true : false
                    }
                    errorText={errors.locationId}
                    required
                  ></Form.Field>
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    type="number"
                    id="departmentId"
                    label={'Department'}
                    onChange={handleChange}
                    value={values.departmentId}
                    disabled={values.isPOValid}
                    error={
                      touched.departmentId && errors.departmentId ? true : false
                    }
                    errorText={errors.departmentId}
                    required
                  ></Form.Field>
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    type="date"
                    id="invoiceDate"
                    label={'Invoice Date'}
                    onChange={handleChange}
                    value={values.invoiceDate}
                    error={
                      touched.invoiceDate && errors.invoiceDate ? true : false
                    }
                    errorText={errors.invoiceDate}
                    required
                  ></Form.Field>
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    type="number"
                    id="productCost"
                    label={'Product Cost'}
                    onChange={handleChange}
                    error={
                      touched.productCost && errors.productCost ? true : false
                    }
                    value={values.productCost}
                    errorText={errors.productCost}
                    required
                  ></Form.Field>
                </Grid.Item>
              </Grid.Container>
            </ExpandableSection.Content>
          </ExpandableSection>
          <br />
          <Divider />
          <ExpandableSection startExpanded padding="dense">
            <Heading size={6}>Additional Costs & Reductions</Heading>
            <ExpandableSection.Content>
              <GridContainer spacing="dense" align="center">
                <Grid.Item xs={6}>
                  <Autocomplete
                    id="aocCode"
                    label="Choose One"
                    onUpdate={(id, value) => {
                      setTempAllowance((prevState: any) => ({
                        ...prevState,
                        allowanceCode: value,
                      }))
                    }}
                    onEnter={() => {}}
                    options={aocCodeOptions}
                    value={tempAllowance.allowanceCode}
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <Form.Field
                    type="number"
                    id="newAllowanceAmount"
                    label={'Allowance/Charge Amount'}
                    onChange={(e: any) => {
                      setTempAllowance((prevState: any) => ({
                        ...prevState,
                        allowanceAmount: e.target.value,
                      }))
                    }}
                    value={tempAllowance.allowanceAmount}
                    // ...other props
                  />
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Button
                    onClick={() => {
                      handleAddAllowanceCode()
                    }}
                    disabled={!isAddButtonEnabled()}
                  >
                    Add
                  </Button>
                </Grid.Item>
              </GridContainer>
              {values.allowanceCosts?.map((allowanceCode, index) => (
                <GridContainer key={index} spacing="dense" align="center">
                  <Grid.Item xs={6}>
                    <Form.Field
                      type="text"
                      id={`allowanceCodes[${index}].code`}
                      label={'Allowance Code'}
                      value={allowanceCode.allowanceCode}
                      disabled
                    />
                  </Grid.Item>
                  <Grid.Item xs={4}>
                    <Form.Field
                      type="number"
                      id={`allowanceCodes[${index}].amount`}
                      label={'Allowance/Charge Amount'}
                      value={allowanceCode.allowanceAmount}
                      disabled
                    />
                  </Grid.Item>
                  <Grid.Item xs={2}>
                    <Button onClick={() => handleRemoveAllowanceCode(index)}>
                      Remove
                    </Button>
                  </Grid.Item>
                </GridContainer>
              ))}
            </ExpandableSection.Content>
          </ExpandableSection>
          <ExpandableSection startExpanded padding="dense">
            <Heading size={6}>Attachments</Heading>
            <ExpandableSection.Content>
              <div>
                <Input.DropArea
                  type="drop-area"
                  id="attachments"
                  instructionText={
                    'Please upload the valid Invoice for the above details'
                  }
                  dropText="Drag and Drop Invoice Here"
                  fullwidth
                  multiple
                  onUpdate={(e: FormDataEvent) => {
                    const files =
                      get(e, 'dataTransfer.files') || get(e, 'target.files')
                    handleAttachments(files)
                  }}
                  required
                ></Input.DropArea>
                <Input.Label
                  className="hc-pt-md"
                  error={
                    touched.attachments && errors.attachments ? true : false
                  }
                >
                  {touched.attachments &&
                    errors.attachments &&
                    errors.attachments}
                </Input.Label>
              </div>
              <GridContainer>
                {values.attachments?.map((file: any) => (
                  <Grid.Item key={file?.file?.id} className="hc-pv-sm" xs={12}>
                    <Caption>
                      <EnterpriseIcon
                        className="dropdownIcon"
                        icon={FileIcon}
                        size="lg"
                        onClick={() => {}}
                      />{' '}
                      &nbsp;
                      {file?.file?.name} &emsp;
                      <EnterpriseIcon
                        className="dropdownIcon"
                        icon={TrashIcon}
                        size={'sm'}
                        onClick={() => {
                          removeAttachment(file)
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </Caption>
                  </Grid.Item>
                ))}
              </GridContainer>
            </ExpandableSection.Content>
          </ExpandableSection>
        </div>
        <Card
          style={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            zIndex: 1000,
          }}
        >
          <Card corners="none" className="hc-pr-lg hc-pb-sm">
            <GridContainer justify="space-between">
              <GridItem xs>
                <Button
                  onClick={() => {
                    resetForm()
                  }}
                >
                  <EnterpriseIcon icon={RedoIcon} />
                  Reset
                </Button>
              </GridItem>
              <GridItem>
                <span className="hc-clr-grey01 hc-fs-md">
                  Invoice Total: &nbsp;
                </span>
                <span>
                  <strong className="hc-fs-md">
                    {getCurrency(formik.values.invoiceAmount, 'USD')}
                  </strong>
                </span>
              </GridItem>
              <GridItem>
                <Button
                  type="primary"
                  onClick={() => {
                    setLoading(true)
                    handleSubmitInvoice('submit', formik)
                    setLoading(false)
                  }}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </GridItem>
              {loading && (
                <GridItem>
                  <Spinner />
                </GridItem>
              )}
            </GridContainer>
          </Card>
        </Card>
      </Form>
    </>
  )
}
