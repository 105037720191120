import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../configs/apiConfig'
import axios from 'axios'
import { PurchaseOrder } from '../types/vendorTypes'

export const usePurchaseOrder = () => {
  const env = useEnv() as EnvConfig

  const getPurchaseOrder = async (
    purchaseOrderId: number
  ): Promise<PurchaseOrder[]> => {
    const res = await axios.get(`${env.baseApiUrl}/purchase_orders/`, {
      params: {
        purchase_order_id: purchaseOrderId,
      },
    })
    return res.data
  }

  return {
    getPurchaseOrder,
  }
}
