import { produce } from 'immer'
import moment from 'moment'

export function getDownloadResults(
  downloadRequests: any | undefined,
  isVendor?: Boolean
): any | undefined {
  let dateFields = ['invoiceDate', 'createTimestamp']

  let resultsData: any = produce(downloadRequests, (draftState: any) => {
    draftState.forEach(function (o: any) {
      Object.keys(o).forEach(function (key: any) {
        if (key in dateFields && o[key] !== null) {
          o[key] = moment(o[key]).format('YYYY-MM-DD')
        }
        if (o[key] === null) {
          o[key] = ''
        }
      })
    })
  })

  return resultsData
}
