import React from 'react'
import {
  Caption,
  Card,
  Divider,
  Grid,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import { useDashboard } from '../../context/Invoices/DashboardProvider'
import { getCompactNumber, getCurrency } from '../../utils/Formatter'

interface CardProps {
  width?: any
}
export const LandingPageCard: React.FC<CardProps> = ({ width }) => {
  const [dashboard] = useDashboard()

  return (
    <Grid.Container>
      {dashboard?.cards?.map(({ text, count, amount }, index) => (
        <Grid.Item lg={width ?? 3} xs={3} key={text}>
          <Card
            className="hc-pa-xl"
            style={{
              height: '170px',
              position: 'relative',
            }}
            elevation={2}
          >
            <Heading size={3} className="hc-clr-grey2">
              {text}
            </Heading>
            <Grid.Container className="hc-pv-md hc-pt-md">
              <Grid.Item>
                <Caption
                  above={<p className="hc-fs-md hc-clr-grey03">Invoices</p>}
                >
                  <span
                    className="hc-fs-xl hc-clr-grey02"
                    style={{ fontWeight: 'bolder' }}
                  >
                    {getCompactNumber(count)} {/* TOD: handleNumberinK/M */}
                  </span>
                </Caption>
              </Grid.Item>
              <Divider direction="vertical" width={1} className="hc-mv-lg" />
              <Grid.Item xs>
                <Caption
                  above={<p className="hc-fs-md hc-clr-grey03">Total Amount</p>}
                >
                  <span
                    className="hc-fs-xl hc-clr-grey02"
                    style={{ fontWeight: 'bolder' }}
                  >
                    {getCurrency(amount, 'USD')} {/* TODO: handleAmountin$ */}
                  </span>
                </Caption>
              </Grid.Item>
            </Grid.Container>
          </Card>
        </Grid.Item>
      ))}
    </Grid.Container>
  )
}
