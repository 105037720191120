import { object, number, string, array, InferType, boolean } from 'yup'

export const bulkInvoiceSchema = object({
  invoiceNumber: string()
    .required('Invoice Number is Mandatory')
    .min(4, 'Invoice Length Should be minimum 4 Chars')
    .max(20, 'Invoice Length Should be maximum 20 Chars')
    .test(
      'duplicate-invoice',
      'This is a duplicate invoice. Invoice number aleady exists in the draft',
      function (value) {
        const invoices = this.options.context?.invoices
        const index = this.options.context?.index
        const firstIndex = invoices.findIndex(
          (invoice: any) =>
            value === invoice.invoiceNumber &&
            this.parent?.vendorNumber === invoice.vendorNumber
        )
        let duplicate = false

        invoices?.forEach((invoice: any, i: number) => {
          if (
            value === invoice.invoiceNumber &&
            this.parent?.vendorNumber === invoice.vendorNumber &&
            index !== i &&
            firstIndex !== index
          ) {
            duplicate = true
          }
        })

        return !duplicate
      }
    ),
  vendorNumber: number()
    .required('Please Select a Valid Vendor number')
    .positive('Please Select a Valid Vendor number'),
  vendorName: string().required(),
  vendorContactName: string().defined(),
  vendorEmailAddress: string().required().email('Not a valid Email'),
  vendorType: string().optional(),
  purchaseOrderNumber: number()
    .required('Purchase Order is Mandatory')
    .test('valid-purchase-order', 'Invalid Purchase Order', function (value) {
      const isWithinRange = value >= 1 && value <= 9999999
      const hasElevenDigits = value.toString().length === 11
      const startsWith1000 = value.toString().startsWith('1000')

      return isWithinRange || (hasElevenDigits && startsWith1000)
    }),
  locationId: string()
    .required('Location ID is mandatory')
    .min(1, 'Invalid Location Id')
    .max(4, 'Invaild Location Id'),
  departmentId: number()
    .required('Department is Mandatory')
    .moreThan(1, 'Invalid Department')
    .lessThan(999, 'Invaild Department'),
  invoiceDate: string()
    .required()
    .test(
      'valid-invoice-date',
      'Inovice Date should be always within 18 months',
      function (value) {
        if (!value) return false
        const invoiceDate = new Date(value)
        const currentDate = new Date()

        const eighteenMonthsAgo = new Date()
        eighteenMonthsAgo.setMonth(currentDate.getMonth() - 18)
        if (invoiceDate.getTime() < eighteenMonthsAgo.getTime()) {
          return false
        }

        return true
      }
    ),
  productCost: number()
    .required()
    .required()
    .moreThan(0, 'Number should be greater then $0'),
  allowanceCosts: array()
    .optional()
    .of(
      object({
        allowanceType: string().required('Allowance Type is required'),
        allowanceCode: string().required('Allowance Code is required'),
        allowanceAmount: number()
          .required('Allowance Amount is required')
          .moreThan(0, 'Number should be greater then 0'),
      })
    )
    .max(5, 'Maximum 5 AOC allocawd'),
  invoiceAmount: number()
    .required()
    .required()
    .moreThan(0, 'Number should be greater then $0'),
  isPOValid: boolean().defined(),
})

export interface BulkInvoiceForm extends InferType<typeof bulkInvoiceSchema> {}
