import React, { useReducer, useEffect } from 'react'
import _ from 'lodash'
import { useMutation } from '@tanstack/react-query'
import { AttachmentMetadata } from '../../api/models/AttachmentResponse'
import {
  AttachmentAction,
  AttachmentDispatchContext,
  AttachmentState,
  AttachmentStateContext,
} from './AttachmentContext'
import { useAttachment } from '../../api/hooks/useAttachments'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

function attachmentReducer(
  state: AttachmentState[],
  action: AttachmentAction
): AttachmentState[] {
  switch (action.type) {
    case 'ADD_ATTACHMENT':
      return [
        ...state,
        {
          name: action.name,
          parentId: action.parentId,
          file: action.file,
          metaData: action.attachmentMetaData,
          status: action.status,
          asyncUpload: action.asyncUpload,
        },
      ]
    case 'ADD_ATTACHMENTS':
      return [
        ...state,
        ...action.attachments.map((item) => ({
          name: item.name,
          parentId: item.parentId,
          file: item.file,
          metaData: item.attachmentMetaData,
          status: item.status,
          asyncUpload: item.asyncUpload,
        })),
      ]
    case 'UPDATE_ATTACHMENT':
      return _.map(state, (attachment) =>
        attachment.name === action.name &&
        attachment.parentId === action.parentId
          ? { ...attachment, status: action?.status, error: action.error }
          : attachment
      )
    case 'CANCEL_ATTACHMENT':
      return _.filter(
        state,
        (attachment) =>
          !(
            attachment.name === action.name &&
            attachment.parentId === action.parentId
          )
      )
    case 'RETRY_ATTACHMENT':
      return _.map(state, (attachment) =>
        attachment.name === action.name &&
        attachment.parentId === action.parentId
          ? { ...attachment, status: 'pending' }
          : attachment
      )
    case 'REMOVE_ATTACHMENT':
      return _.remove(
        state,
        (attachment) =>
          !(
            attachment.name === action.name &&
            attachment.parentId === action.parentId
          )
      )
    default:
      return state
  }
}

export function AttachmentProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const makeToast = useToaster()
  const [state, dispatch] = useReducer(attachmentReducer, [])
  const { uploadAttachment } = useAttachment()
  const maxConcurrentUploads = 2

  async function uploadAttachmentApi(attachment: AttachmentState) {
    const res = await uploadAttachment(attachment.metaData, attachment.file)
    if (res !== null) {
      makeToast({
        autoHideDuration: 5000,
        type: 'success',
        heading: 'Successfully updated documents!',
        message: 'Successfully updated document.',
      })
    }
    return res
  }

  const { mutate, mutateAsync } = useMutation(uploadAttachmentApi, {
    retry: 1,
    onError: (error: any, variables) => {
      dispatch({
        type: 'UPDATE_ATTACHMENT',
        name: variables.name,
        parentId: variables.parentId,
        status: 'error',
        error: error.message,
      })
    },
    onSuccess: (data, variables) => {
      dispatch({
        type: 'UPDATE_ATTACHMENT',
        name: variables.name,
        parentId: variables.parentId,
        status: 'completed',
      })
    },
  })

  useEffect(() => {
    const interval = setInterval(() => {
      // Get the number of attachments currently being uploaded
      const uploadCount = state.filter(
        (attachment) => attachment.status === 'uploading'
      ).length

      // If there are fewer uploads than the maximum allowed, start uploading pending attachments
      if (uploadCount < maxConcurrentUploads) {
        const pendingAttachments = state.filter(
          (attachment) =>
            attachment?.status === 'pending' && attachment.asyncUpload
        )
        pendingAttachments
          .slice(0, maxConcurrentUploads - uploadCount)
          .forEach((attachment) => {
            dispatch({
              type: 'UPDATE_ATTACHMENT',
              name: attachment.name,
              parentId: attachment.parentId,
              status: 'uploading',
            })
            mutate(attachment)
          })
      }
      state
        .filter(
          (attachment) =>
            attachment?.status === 'completed' && attachment.asyncUpload
        )
        .forEach((attachment) => {
          dispatch({
            type: 'REMOVE_ATTACHMENT',
            parentId: attachment.parentId,
            name: attachment.name,
          })
        })
    }, 500)

    return () => clearInterval(interval)
  }, [state, mutate])

  const upload = async (
    attachments: {
      name: string
      parentId: string
      file: Blob
      attachmentMetaData: AttachmentMetadata
      asyncUpload: boolean
    }[]
  ) => {
    dispatch({
      type: 'ADD_ATTACHMENTS',
      attachments: attachments.map((attachment) => ({
        ...attachment,
        status: 'pending',
      })),
    })

    for (let i = 0; i < attachments.length; i++) {
      let attachment = attachments[i]

      const attachmentState: AttachmentState = {
        name: attachment.name,
        parentId: attachment.parentId,
        file: attachment.file,
        status: 'uploading',
        asyncUpload: false,
        metaData: attachment.attachmentMetaData,
      }

      dispatch({
        type: 'UPDATE_ATTACHMENT',
        name: attachment.name,
        parentId: attachment.parentId,
        status: 'uploading',
      })

      await mutateAsync(attachmentState)
    }
  }

  const retryOnce = async (name: string, parentId: string) => {
    const attachmentState = state.filter(
      (attachment) =>
        attachment.name === name && attachment.parentId === parentId
    )[0]

    dispatch({ type: 'RETRY_ATTACHMENT', name, parentId })
    dispatch({
      type: 'UPDATE_ATTACHMENT',
      name: name,
      parentId: parentId,
      status: 'uploading',
    })
    await mutateAsync(attachmentState)
  }

  return (
    <AttachmentStateContext.Provider value={state}>
      <AttachmentDispatchContext.Provider
        value={{ dispatch, upload, retryOnce }}
      >
        {children}
      </AttachmentDispatchContext.Provider>
    </AttachmentStateContext.Provider>
  )
}
