import { useEffect, useReducer, useContext, useState } from 'react'
import { useAuth } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import {
  Role,
  UserProfileAction,
  UserProfileDispatchContext,
  UserProfileState,
  UserProfileStateContext,
} from './UserProfileContext'
import { EnvConfig } from '../../configs/apiConfig'
import { useQueryClient } from '@tanstack/react-query'
import { useVendorApi } from '../../api/hooks/useVendorApi'
import Unauthorized from '../../globalComponents/Unauthorized'

type Props = {
  initialState?: UserProfileState
  children?: React.ReactElement
}

const reducer = (
  state: UserProfileState,
  action: UserProfileAction
): UserProfileState => {
  switch (action.type) {
    case 'SET_USER_ROLE':
      return {
        ...state,
        userId: action.userId,
        userRole: action.userRole,
        isVendor: action.isVendor,
        isAdmin: action.isAdmin,
      }
    case 'SET_VENDOR_LIST':
      return { ...state, vendorList: [...action.vendorList] }
    default:
      return { ...state }
  }
}

export const UserProfileProvider: React.FC<Props> = ({
  initialState = {},
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { session, isAuthorized, isAuthenticated } = useAuth()
  const { auth } = useEnv() as EnvConfig
  const { getVendorList } = useVendorApi()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const client = useQueryClient()

  useEffect(() => {
    const userRole = getUserRole()
    dispatch({
      type: 'SET_USER_ROLE',
      userId: session?.userInfo?.lanId ?? '',
      isVendor: userRole === Role.VENDOR,
      userRole: userRole,
      isAdmin: userRole === Role.ADMIN,
    })

    const fetchVendorList = async () => {
      try {
        setIsLoading(true)
        const data = await client.fetchQuery({
          queryKey: ['getVendorfrombp'],
          queryFn: () => getVendorList(session?.userInfo?.lanId ?? ''),
        })
        dispatch({
          type: 'SET_VENDOR_LIST',
          vendorList: [...data],
        })
      } catch (err: any) {
        setError(err)
      } finally {
        setIsLoading(false)
      }
    }
    if (userRole === Role.VENDOR) {
      fetchVendorList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getUserRole(): Role | undefined {
    if (isAuthorized([...auth.adminRole])) {
      return Role.ADMIN
    }
    if (isAuthorized([...auth.tmRole])) {
      return Role.USER
    }
    if (isAuthorized([...auth.vendorRole])) {
      return Role.VENDOR
    }
    console.log('Unauth: Member of', session?.userInfo?.memberOf)
    return undefined
  }

  if (isLoading) {
    return <div>Loading....</div>
  }

  if (
    !isAuthorized([...auth.tmRole, ...auth.adminRole]) &&
    isAuthenticated() &&
    error
  ) {
    return <p>Something went wrong!!! Please refresh and try again!!!</p>
  }

  if (isAuthenticated() && state.userRole === undefined) {
    return (
      <Unauthorized
        isVendor={auth.authorizationUrl.includes('partnersonline')}
      />
    )
  }

  // if (
  //   state.userRole === Role.VENDOR &&
  //   !isPilotVendor(allowedVendors, state.vendorList)
  // ) {
  //   return <Unauthorized isVendor />
  // }

  return (
    <UserProfileStateContext.Provider value={state}>
      <UserProfileDispatchContext.Provider value={dispatch}>
        {children}
      </UserProfileDispatchContext.Provider>
    </UserProfileStateContext.Provider>
  )
}

function useUserProfile(): [
  UserProfileState,
  React.Dispatch<UserProfileAction>
] {
  const stateContext = useContext(UserProfileStateContext)
  const dispatch = useContext(UserProfileDispatchContext)
  return [stateContext as UserProfileState, dispatch]
}

// function isPilotVendor(
//   pilotVendor: String[],
//   vendorList: Vendor[] | undefined
// ): Boolean {
//   if (!vendorList || vendorList.length === 0) {
//     return false
//   }
//   return pilotVendor.some((pilot) =>
//     vendorList.some((vendor) => vendor.vendorId === pilot)
//   )
// }

export { useUserProfile }
