import { Grid, Input } from '@enterprise-ui/canvas-ui-react'
import React from 'react'
import { getCurrency } from '../../utils/Formatter'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'

interface SectionItemProps {
  label: any
  value: any
  type: any
}

export const SectionItem: React.FC<SectionItemProps> = ({
  label,
  value,
  type,
}) => {
  return (
    <Grid.Container>
      <Grid.Item xs={4}>
        <Input.Label className="hc-fs-xs hc-clr-grey01">{label}:</Input.Label>
      </Grid.Item>
      <Grid.Item xs={6}>
        <Input.Label className="hc-fs-xs">
          {type === 'string' &&
            (value !== undefined && value !== '' ? value : 'None')}
          {type === 'date' &&
            (value != null && value !== '' ? (
              <DateFormatter date={value} format="MM-DD-YYYY" />
            ) : (
              'None'
            ))}
          {type === 'amount' &&
            (value !== undefined && value !== ''
              ? `${getCurrency(value, 'USD')}`
              : 'None')}

          {type === 'amount_strong' &&
            (value !== undefined && value !== '' ? (
              <strong>{getCurrency(value, 'USD')}</strong>
            ) : (
              'None'
            ))}
        </Input.Label>
      </Grid.Item>
    </Grid.Container>
  )
}
