import { PurchaseOrder } from '../../api/types/vendorTypes'
import { AttachmentMetadata } from '../../api/models/AttachmentResponse'
import {
  InvoiceResponse,
  NewInvoiceRequest,
} from '../../api/models/InvoiceResponse'
import { InvoiceForm } from './SubmitInvoiceWrapper'
import { NewExemptionVendor } from '../../api/models/ExemptionVendor'
import moment from 'moment'
import { ExemptionVendorForm } from '../ExemptionVendor/AddVendorWrapper'
import { DraftInvoice } from '../../api/models/Draft'

export function formatCreateInvoice(
  invoiceForm: InvoiceForm
): NewInvoiceRequest {
  return {
    invoiceNumber: invoiceForm.invoiceNumber,
    vendorNumber: invoiceForm.vendorNumber,
    vendorName: invoiceForm.vendorName,
    vendorContactName: invoiceForm.vendorContactName,
    vendorEmailAddress: invoiceForm.vendorEmailAddress,
    vendorType: invoiceForm.vendorType,
    purchaseOrderNumber: invoiceForm.purchaseOrderNumber,
    locationId: invoiceForm.locationId,
    departmentId: invoiceForm.departmentId.toString(),
    invoiceDate: invoiceForm.invoiceDate,
    productCost: invoiceForm.productCost,
    invoiceAmount: invoiceForm.invoiceAmount,
    allowanceCosts: invoiceForm.allowanceCosts?.map((aocCost) => ({
      allowanceType: aocCost.allowanceType,
      allowanceCode: aocCost.allowanceCode,
      allowanceAmount: aocCost.allowanceAmount,
    })),
    status: 'New',
    auditEntry: {
      userId: 'Admin',
      activityDescription: 'New Case Created',
      status: 'New',
    },
  }
}

export function formatAttachmentMetadata(
  invoice: InvoiceResponse
): AttachmentMetadata {
  return {
    dept_id: Number(invoice.departmentId),
    invoice_id: invoice.invoiceNumber.toString(), //TODO: invoiceNumber or invoiceId
    document_type: 'Invoice',
    is_internal: false,
    location_id: invoice.locationId!!.toString(),
    po_number: invoice.purchaseOrderNumber,
    request_id: invoice.invoiceId,
    vendor_id: invoice.vendorNumber,
  }
}

export function formatBulkAttachmentMetadata(
  draftInvoice: DraftInvoice
): AttachmentMetadata {
  const { invoice, invoiceId } = draftInvoice
  return {
    dept_id: Number(invoice.departmentId),
    invoice_id: invoice.invoiceNumber.toString(), //TODO: invoiceNumber or invoiceId
    document_type: 'Invoice',
    is_internal: false,
    location_id: invoice.locationId!!.toString(),
    po_number: invoice.purchaseOrderNumber,
    request_id: invoiceId!!,
    vendor_id: invoice.vendorNumber,
  }
}

export function formatPurchaseOrderData(
  purchaseOrders: PurchaseOrder[]
): [number, { value: string; label: string }[]] {
  const departmentId = Number(purchaseOrders[0].departmentId)
  const locations = purchaseOrders.map((po) =>
    convertForSelectOption(po.location.toString())
  )

  return [departmentId, locations]
}

export const convertForSelectOption = (v: string) => ({
  value: v,
  label: v,
})

export function formatVendor(
  vendorForm: ExemptionVendorForm
): NewExemptionVendor {
  const currentDateTime = moment()
  const formattedDateTime = currentDateTime.format('YYYY-MM-DDTHH:mm:ss.SSS')
  return {
    vendorNumber: vendorForm.vendorNumber,
    vendorName: vendorForm.vendorName,
    active: vendorForm.active,
    permanentExemptFlag: vendorForm.permanentExemptFlag,
    exemptStartDate: vendorForm.exemptStartDate,
    exemptEndDate: vendorForm.exemptEndDate,
    auditEntry: {
      activityDescription: 'added a vendor',
      activityTimestamp: formattedDateTime,
      userId: 'admin',
      orderNumber: 1,
      comment: '',
    },
  }
}
