import React, { createContext } from 'react'
import { AttachmentMetadata } from '../../api/models/AttachmentResponse'

export type AttachmentAction =
  | {
      type: 'ADD_ATTACHMENT'
      name: string
      parentId: string
      file: Blob
      attachmentMetaData: AttachmentMetadata
      status: AttachmentState['status']
      asyncUpload: boolean
    }
  | {
      type: 'ADD_ATTACHMENTS'
      attachments: {
        name: string
        parentId: string
        file: Blob
        attachmentMetaData: AttachmentMetadata
        status: AttachmentState['status']
        asyncUpload: boolean
      }[]
    }
  | {
      type: 'UPDATE_ATTACHMENT'
      name: string
      parentId: string
      status: AttachmentState['status']
      error?: string
    }
  | { type: 'CANCEL_ATTACHMENT'; name: string; parentId: string }
  | { type: 'RETRY_ATTACHMENT'; name: string; parentId: string }
  | { type: 'REMOVE_ATTACHMENT'; name: string; parentId: string }

export type AttachmentState = {
  name: string
  parentId: string
  file: Blob
  status: 'pending' | 'uploading' | 'completed' | 'error' | 'cancelled'
  type?: string
  error?: string
  asyncUpload: boolean
  metaData: AttachmentMetadata
}

type AttachmentDispatch = {
  dispatch: React.Dispatch<AttachmentAction>
  upload: (
    attachments: {
      name: string
      parentId: string
      file: Blob
      attachmentMetaData: AttachmentMetadata
      asyncUpload: boolean
    }[]
  ) => void
  retryOnce: (name: string, parentId: string) => void
}

const AttachmentStateContext = createContext<AttachmentState[]>([])
AttachmentStateContext.displayName = 'AttachmentStateContext'

const AttachmentDispatchContext = createContext<AttachmentDispatch>({
  dispatch: () => null,
  upload: (
    attachments: {
      name: string
      parentId: string
      file: Blob
      attachmentMetaData: AttachmentMetadata
      asyncUpload: boolean
    }[]
  ) => null,
  retryOnce: (name: string, parentId: string) => null,
})

AttachmentDispatchContext.displayName = 'AttachmentDispatchContext'

export { AttachmentStateContext, AttachmentDispatchContext }

//TODO: table with data grid
